/* Module: S */
.related-content {
    padding: pxToRem(48) 0 pxToRem(64) 0;
    margin-bottom: pxToRem(-64);

    &__wrapper {
        @extend %clearfix;
        max-width: $page__max-width;
        margin: pxToRem(-12) auto 0 auto;
        @include sq-flex-box;
        @include sq-flex-wrap(wrap);
    }

    &__item {
        float: left;
        padding: pxToRem(22) pxToRem(20);
        width: 100%;
    }

    &__item--single {
        margin: 0 auto;
    }

    &__image {
        padding-bottom: pxToRem(18);

        img {
            width: 100%;
            border-radius: pxToRem(10);
        }

       .related-content--hidden-images & {
            display: none;
        }
    }

    &__date {
        font-size: pxToRem(12);
        font-weight: 500;
        line-height: 2.08;
        text-transform: uppercase;
        color: $dark-cyan;
        font-weight: bold;
    }

    &__text {
        font-size: pxToRem(14);
        line-height: 1.64;
        color: $greyish-brown;
        padding-top: pxToRem(13);
    }

    &__fallback-text {
        padding: pxToRem(22) pxToRem(20);
        width: 100%;
        text-align: center;
    }

    h3 {
        color: $orange;
        font-size: pxToRem(18);
        font-weight: normal;
        line-height: 1.39;
        margin: 0;

        a {
            text-decoration: none;
            color: $orange;

            &:hover {
                text-decoration: underline;
                color: $orange;
            }
        }
    }

    h2 {
        color: $dark-cyan;
    }

    .main-content__wrapper & {
        margin: 0;
        padding: 0;
    }
}
