/* Module: S */
.social-list {
    max-width: $page__max-width;
    margin: 0 auto;
    padding: pxToRem(30) 0;

    &__wrapper {
        @extend %clearfix;
    }

    &__item {
        float: left;
        padding: pxToRem(10) pxToRem(20) pxToRem(30) pxToRem(20);
        width: 100%;

        > a {
            width: 100%;
            display: block;
            position: relative;
        }
    }

    &__logo {
        float: left;
        padding-right: pxToRem(18);

        img {
            border-radius: 50%;
        }
    }

    &__info {
        float: left;
        padding-bottom: pxToRem(15);
    }

    &__author {
        font-size: pxToRem(18);
        font-weight: bold;
        line-height: 1.39;
    }

    &__time {
        font-size: pxToRem(12);
        font-weight: normal;
        line-height: 1.92;
        color: $white-six;
        padding-top: 0;
    }

    &__content {
        width: 100%;
        float: left;
        font-size: pxToRem(14);
        line-height: 1.64;
        color: $greyish-brown;

        a {
            color: $deep-turquoise;
            text-decoration: none;
            word-wrap: break-word;

            &:hover {
                color: $deep-turquoise;
                text-decoration: underline;
            }

            &:after {
                display: none;
            }
        }

        img {
            margin-top: pxToRem(20);
            display: block;
        }
    }
}