/* Module: S */
.logo__link {
  font-size: pxToRem(38);
  line-height: pxToRem(40);
  width: pxToRem(400);
  padding: pxToRem(24) pxToRem(25) pxToRem(21) pxToRem(20);
}

.mega-menu__feature-description {
  font-size: pxToRem(18);
}

.mega-menu__feature-link {
  font-size: pxToRem(23);
}

.mega-menu__sub-nav-link,
.mega-menu__sub-nav-text {
  font-size: pxToRem(18);
}

.top-nav__list {
  li {
    &.highlighted {
      a {
        &:before {
          width: 9600px;
        }
      }
    }
  }
}
