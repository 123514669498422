/* Module: S */
.featured-display {
    background-color: $white;

    &__wrapper {
        max-width: $page__max-width;
        margin: 0 auto;
        padding: pxToRem(44) 0;
        @include sq-flex-box();
        @include sq-flex-wrap(wrap);
    }

    &__item {
        float: left;
        padding: pxToRem(12) pxToRem(20) pxToRem(48) pxToRem(20);
        width: 100%;
        position: relative;
        text-align: center;
        @include font-smoothing;

        // &:after {
        //     content: '';
        //     height: 1px;
        //     position: absolute;
        //     bottom: pxToRem(19);
        //     left: pxToRem(20);
        //     right: pxToRem(20);
        //     background-color: $greyish-brown-two;
        // }

        &:first-child {
            //padding-bottom: pxToRem(12);
            text-align: left;

            &:after {
                display: none;
            }
        }

        &:last-child {
            padding-bottom: pxToRem(12);

            &:after {
                display: none;
            }
        }

        a {
            color: $white;

            &:hover {
                color: $white;
            }
        }

        h2 {
            font-size: pxToRem(30);
            font-weight: normal;
            font-family: $secondary__font-family;
            line-height: 1.33;
            color: $dark-cyan;
            padding: 0 pxToRem(20) 0 0;
            margin: pxToRem(-4) 0;
        }

        h3 {
            font-size: pxToRem(80);
            line-height: 1;
            font-weight: normal;
            color: $pumpkin;
            padding: 0;
            margin: 0;
        }

        p {
            font-size: pxToRem(18);
            line-height: 1.4;
            color: $black-five;
            padding: pxToRem(24) pxToRem(20) 0 pxToRem(20);
            margin: 0;
        }

        img {
            width: auto;
        }
    }

    sub, sup {
        font-size: 60%;
    }

    sub {
        bottom: 0;
    }
}