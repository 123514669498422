/* Module: S */
.landscape-carousel__image {
    height: pxToRem(600);
}

.landscape-carousel__caption-wrapper {
    top: auto;
    bottom: pxToRem(150);
    @include sq-transform(translate(-50%, 0));
}

.landscape-carousel__controls {
    bottom: 2rem;
}

.landscape-carousel__caption {
    width: auto;
    max-width: pxToRem(600);
}