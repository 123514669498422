/* Module: S */
.featured-images {
    padding: pxToRem(76) 0 pxToRem(50) 0;

    &__wrapper {
        padding-top: pxToRem(14);
    }

    &__item {
        width: 50%;
        margin-top: pxToRem(20);
    }
}
