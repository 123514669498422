/* Module: S */
.gradient__wrapper {
    background: #f3f3f3;
    background: -moz-linear-gradient(left, #f3f3f3 0%, #ffffff 50%, #2b2b2b 50%);
    background: -webkit-linear-gradient(left, #f3f3f3 0%,#ffffff 50%, #2b2b2b 50%);
    background: linear-gradient(to right, #f3f3f3 0%,#ffffff 50%, #2b2b2b 50%);
    padding-top: 4rem;
    padding-bottom: pxToRem(80);

    .large-box {
        &:nth-child(2) {
            .feature-box__heading,
            .feature-box__content {
                color: #fff;
            }
        }
    }
}

.feature-box__link {
    .large-box & {
        padding: 0;
    }
}

.feature-panels__wrapper {
    padding-left: 2rem;
    padding-right: 2rem;

    .gradient__wrapper + & {
        padding-top: 0;
        margin-top: -2rem;
    }
}

.feature-box__heading {
    .large-box & {
        text-align: left;
        padding-bottom: 1rem;
    }
}

.feature-box__container {
    width: calc(100% / 2 - 10px);

    &.large-box {
        width: 50%;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    &:not(.large-box) {
        @include drop-shadow();
    }

    &:nth-child(2) {
        margin-top: 0;
    }

    &:nth-child(n+3) {
        margin-top: 3rem;
    }
}

.feature-box__image {
    .large-box & {
        display: inherit;
    }
}

.feature-box__content {
    font-size: 1rem;
    line-height: 1.75;
    @include sq-flex(1 0 auto);

    .large-box & {
        display: inherit;
        padding-top: 1.25rem;
        font-size: 1rem;
        line-height: pxToRem(28);
    }
}
