$white: #fff;
$white-two: #ebebeb;
$white-three: #d8d8d8;
$white-four: #f7f7f7;
$white-five: #d7d7d7;
$white-six: #767676;

$black: #000;
$black-two: #282828;
$black-three: #020202;
$black-four: #2a2a2a;
$black-five: #4d4d4d;

$greyish-brown: #484848;
$greyish-brown-two: #3f3f3f;
$pale-grey: #ebf5f6;
$palest-grey: #808080;

$duck-egg-blue: #b8e6f2;
$turquoise-blue: #1DB6C3;
$deep-turquoise: #006f78;
$dark-cyan: #146cfd; // used to be #0b70b8;//
$dark-cyan--font: #006fb9;
$ocean: #00828c;
$light-navy: #165464;
$dark-navy: #083871;

$orange: #f37321;
$orange--font: #d05420;
$orange--font-alt: #c94f26;
$pumpkin: #d15420;

$very-light-pink: #fff3f3;
$scarlet: #c80407;

// COLORS PALETTE
$color__primary: $orange;
$color__secondary: $black;
$color__tertiary: $dark-cyan;
$color__quaternary: $black-four;

// BASE FONT SETTINGS
$base__font-scale: 1;  // The font scale multiplier (e.g. 2 = 32px/2em/2rem)
$base__font-pixel: 16; // Used for font size calculations & conversions
$base__font-colour: $greyish-brown;
$base__font-family: "Gotham A", "Gotham B", sans-serif;
$base__font-family-publicsans: "Public Sans", sans-serif;
$secondary__font-family: "Public Sans", "Public Sans", sans-serif;
//$base__font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;
$base__line-height: 1.4;
$base__bg: $white;

$heading__font-color: $color__tertiary;

$page__max-width: 84rem;

// GLOBAL LINKS
$global-link__color: $color__primary;
$global-link__color--hover: $color__primary;

// Selection highlighting
$content-selection__highlight: #b3d4fc;

// BREADCRUMBS
$breadcrumbs-links__color: $base__font-colour;
$breadcrumbs-links__color--hover: $breadcrumbs-links__color;
$breadcrumbs-divider__color: $color__primary;

// LHS MENU
$lhs-menu__width: 15.3125rem;      // 245px
$lhs-menu__margin-right: 2rem;     // This variable needs to have same unit as $rhs-column__margin-left
$lhs-menu__border-bottom: #294c91;
$lhs-menu__mobile-toggler__color: #fff;
$lhs-menu__mobile-toggler__color--active: $lhs-menu__mobile-toggler__color;
$lhs-menu__mobile-toggler__bg: $color__secondary;
$lhs-menu__mobile-toggler__bg--active: $color__primary;
$lhs-menu__mobile-toggler-arrow__color: $lhs-menu__mobile-toggler__color;
$lhs-menu__mobile-toggler-arrow__color--active: $lhs-menu__mobile-toggler-arrow__color;

$lhs-menu__level-1__color: #fff;
$lhs-menu__level-1__bg: $color__tertiary;
$lhs-menu__level-1__color--hover: $lhs-menu__level-1__color;
$lhs-menu__level-1__bg--hover: lighten($lhs-menu__level-1__bg, 5%);
$lhs-menu__level-1__color--active: #fff;
$lhs-menu__level-1__bg--active: $lhs-menu__level-1__bg;
$lhs-menu__level-1-arrow__color: $lhs-menu__level-1__color;

$lhs-menu__level-2__color: #fff;
$lhs-menu__level-2__bg: $color__tertiary;
$lhs-menu__level-2__color--hover: $lhs-menu__level-2__color;
$lhs-menu__level-2__bg--hover: lighten($lhs-menu__level-2__bg, 5%);
$lhs-menu__level-2__color--active: #fff;
$lhs-menu__level-2__bg--active: $lhs-menu__level-2__bg;
$lhs-menu__level-2-arrow__color: $lhs-menu__level-2__color;

$lhs-menu__level-3__color: #fff;
$lhs-menu__level-3__bg: $color__tertiary;
$lhs-menu__level-3__color--hover: $lhs-menu__level-3__color;
$lhs-menu__level-3__bg--hover: lighten($lhs-menu__level-3__bg, 5%);
$lhs-menu__level-3__color--active: #fff;
$lhs-menu__level-3__bg--active: $lhs-menu__level-3__bg;
$lhs-menu__level-3-arrow__color: $lhs-menu__level-3__color;

$lhs-menu__level-4__color: #fff;
$lhs-menu__level-4__bg: $color__tertiary;
$lhs-menu__level-4__color--hover: $lhs-menu__level-4__color;
$lhs-menu__level-4__bg--hover: lighten($lhs-menu__level-4__bg, 5%);
$lhs-menu__level-4__color--active: #fff;
$lhs-menu__level-4__bg--active: $lhs-menu__level-4__bg;
$lhs-menu__level-4-arrow__color: $lhs-menu__level-4__color;

$lhs-menu__level-5__color: #fff;
$lhs-menu__level-5__bg: $color__tertiary;
$lhs-menu__level-5__color--hover: $lhs-menu__level-5__color;
$lhs-menu__level-5__bg--hover: lighten($lhs-menu__level-5__bg, 5%);
$lhs-menu__level-5__color--active: #fff;
$lhs-menu__level-5__bg--active: $lhs-menu__level-5__bg;
$lhs-menu__level-5-arrow__color: $lhs-menu__level-5__color;

// RHS COLUMN
$rhs-column__width: 15.3125rem;   // 245px
$rhs-column__margin-left: 3rem;   // This variable needs to have same unit as $lhs-menu__margin-right

// RHS PANEL
$rhs-panel__color: $base__font-colour;
$rhs-panel__bg: #fff;
$rhs-panel__margin-bottom: 2.5rem;
$rhs-panel-heading__color: $color__tertiary;
$rhs-panel-heading__bg: lighten($color__tertiary, 30%);
$rhs-panel-content__color: #58595b;
$rhs-panel-link__color: #333;
$rhs-panel-link__color--hover: $color__primary;
$rhs-panel-link__bg: transparent;
$rhs-panel-link__bg--hover: $rhs-panel-link__bg;
$rhs-panel-link-arrow__color: $color__primary;
$rhs-panel-link-arrow__color--hover: $rhs-panel-link-arrow__color;

// BLOCKQUOTE SETTINGS
$blockquote__background: transparent;
$blockquote__color: #1b325f;
$blockquote-quotemarks__color: $color__primary;

// HOMEPAGE CONTENT BOX
$home-box-heading__bg: #fff;
$home-box-heading__bg--hover: $color__tertiary;
$home-box-heading__color: $color__tertiary;
$home-box-heading__color--hover: #fff;
$home-box__bg: #fff;
$home-box-text__color: #2b2b2b;
$home-box-links__color: $base__font-colour;
$home-box-links__color--hover: $color__primary;
$home-box-image-hover-overlay__bg: rgba($color__tertiary, .35);
$home-box-large-line-separator__color: $color__tertiary;

// CAROUSEL
$landscape-carousel-caption__color: #fff;
$landscape-carousel-caption-heading__border: #fff;
$landscape-carousel-caption__bg: $color__tertiary;

// TABLE SETTINGS
$table-heading__bg: $color__tertiary;
$table-heading__color: #fff;
$table-row-odd__bg: transparent;
$table-row-odd__bg--hover: #fff;
$table-row-even__bg: transparent;
$table-row-even__bg--hover: #fff;
$table-row__border: 2px solid darken(#fff, 20%);

// FORM SETTINGS
$form-links__color: #393939;
$form-links__color--hover: $color__primary;
$form-separator__color: $color__tertiary;

$form-control__color: inherit;
$form-control__color--focus: #464a4c;
$form-control__bg: #fff;
$form-control__bg--focus: #fff;
$form-control__border: 2px solid #e3e3e3;
$form-control__border--focus: 2px solid darken(#e3e3e3, 10%);
$form-control__border-radius: 0;

$form-control-success__color: #5cb85c;
$form-control-success__border: 2px solid $form-control-success__color;

$form-control-warning__color: #f0ad4e;
$form-control-warning__border: 2px solid $form-control-warning__color;

$form-control-error__color: #d9534f;
$form-control-error__border: 2px solid $form-control-error__color;

$form-text__font-size: 85%; // of 1rem;
$form-text__color: #636c72;

$form-check__color: $base__font-colour;
$form-check__color--disabled: lighten($base__font-colour, 15%);

// BUTTONS SETTINGS
$btn-primary__color: #1b325f;
$btn-primary__color--hover: #fff;
$btn-primary__bg: transparent;
$btn-primary__bg--hover: #1b325f;
$btn-primary__border: #1b325f;
$btn-primary__border--hover: $btn-primary__border;

$btn-secondary__color: #777;
$btn-secondary__color--hover: #fff;
$btn-secondary__bg: transparent;
$btn-secondary__bg--hover: #777;
$btn-secondary__border: #777;
$btn-secondary__border--hover: $btn-secondary__border;

$btn-success__color: #5cb85c;
$btn-success__color--hover: #fff;
$btn-success__bg: transparent;
$btn-success__bg--hover: #5cb85c;
$btn-success__border: #5cb85c;
$btn-success__border--hover: $btn-success__border;

$btn-info__color: #5bc0de;
$btn-info__color--hover: #fff;
$btn-info__bg: transparent;
$btn-info__bg--hover: #5bc0de;
$btn-info__border: #5bc0de;
$btn-info__border--hover: $btn-info__bg--hover;

$btn-warning__color: #f0ad4e;
$btn-warning__color--hover: #fff;
$btn-warning__bg: transparent;
$btn-warning__bg--hover: #f0ad4e;
$btn-warning__border: #f0ad4e;
$btn-warning__border--hover: $btn-warning__border;

$btn-danger__color: #d9534f;
$btn-danger__color--hover: #fff;
$btn-danger__bg: transparent;
$btn-danger__bg--hover: #d9534f;
$btn-danger__border: #d9534f;
$btn-danger__border--hover: $btn-danger__border;
