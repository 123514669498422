/* Module: S */
.news__info {
    margin-bottom: 1.5rem;

    h1,
    h2 {
        line-height: 1.15;
        font-size: 2rem;
        margin-top: 0;
        margin-bottom: .5rem;
    }
}

.news__publication {
    time {
        font-weight: bold;
        font-style: italic;
    }
}

.news__summary {
    font-weight: 600;
    margin-bottom: 1rem;
}

.event__info {
    margin-bottom: 1rem;
}

.event__info-inner {
    margin-bottom: .75rem;
    h1,
    h2 {
        line-height: 1.15;
        font-size: 2rem;
        margin-top: 0;
        margin-bottom: 1rem;
    }
}

.event__details {
    margin-bottom: 1rem;
}

.event__content {
    @extend %clearfix;
}

.event__map {
    height: 100%;
    width: 100%;
    max-height: 400px;
    max-width: 400px;
    border: 1px solid #999;

    &.pull-right {
        margin-left: .75rem;
    }

    &.pull-left {
        margin-right: .75rem;
    }

    @media screen and (max-width: 35.3125rem) {
        max-width: none;
        float: none!important;
        margin: .5rem 0!important;
    }
}


.event__info-list {
    @extend %list-reset;

    li {
        line-height: 1.75;
    }

    span {
        font-weight: 400;
    }
}

.rss-icon {
    fill: #fff;
    height: 1rem;
    width: 1rem;
}
