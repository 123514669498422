/* Module: S */
.main-header .twitter-typeahead {
    width: 100%;
}

.search-page {
    &.search-page-single {
        margin-top: pxToRem(60);
        max-width: pxToRem(500);
    }

}