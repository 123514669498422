/* Module: S */
.section--pathways {
    position: relative;
    padding-top: pxToRem(280);
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        top: pxToRem(-55);
        left: auto;
        width: 100%;
        height: pxToRem(282);
        background-image: url(../mysource_files/HETI_logo_white.svg);
        background-repeat: no-repeat;
        background-position: center;

    }

    // &:after {
    //     content: '';
    //     position: absolute;
    //     bottom: pxToRem(130);
    //     right: pxToRem(-80);
    //     width: pxToRem(138);
    //     height: pxToRem(68);
    //     background-image: url(../mysource_files/bg-figure.svg);
    //     transform: rotate(180deg);
    //     background-position: pxToRem(138) pxToRem(350);
    //
    // }
}

.featured-people {
    max-width: $page__max-width;
    margin: 0 auto;
    padding-bottom: 0;

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        width: 100%;
        padding-bottom: pxToRem(75);
    }

    &__item {
        padding: pxToRem(10) pxToRem(20);
        margin-bottom: pxToRem(20);
        width: 100%;
        position: relative;

        > .featured-people__avatar {
            width:  pxToRem(258);
            height: pxToRem(258);
            //padding-bottom: 100%;
            display: block;
            background-size: cover;
            background-position: top center;
            margin: auto;

            position: relative;
            cursor: pointer;
            border-radius: 50%;
        }

        .featured-people__info-wrap {
            display: block;
            padding-top: 1.5rem;

            .featured-people__info {
                width: 100%;
                text-align: center;
            }
        }

        &.active {
            // a {
            //     height: pxToRem(293);
            // }
            .featured-people {
                &__content {
                    display: block;
                }
            }
        }
    }

    &__name {
        font-size: pxToRem(18);
        font-weight: bold;
        line-height: 1.83;
        text-transform: uppercase;
        color: $white;
    }

    &__position {
        font-size: pxToRem(25);
        font-weight: 400;
        line-height: 1.22;
        color: $white;
        @include font-smoothing;
    }

    &__content {
        display: none;
        padding: 0 pxToRem(20) pxToRem(30) pxToRem(20);
        color: $greyish-brown;
        background-color: $white;
        position: relative;
        border-radius: pxToRem(15);
        margin-top: 1rem;

        h3 {
            font-size: pxToRem(24);
            font-weight: 500;
            padding: pxToRem(50) 0 0 0;
            margin: 0;
        }

        p {
            font-size: pxToRem(14);
            line-height: 1.64;
        }

        button {
            position: absolute;
            top: pxToRem(12);
            right: pxToRem(12);
            width: pxToRem(39);
            height: pxToRem(39);
            background: transparent;
            border: none;
            padding: pxToRem(12);

            svg {
                fill: #006f78;
                width: pxToRem(15)
            }
        }

        a {
            color: $greyish-brown;

            &:hover {
                color: $greyish-brown;
            }

        }
    }

    &__link {
        font-size: pxToRem(25);
        line-height: 1.4;
        text-align: center;
        padding: pxToRem(60) pxToRem(20) pxToRem(30) pxToRem(20);
        color: $dark-cyan--font;
        background: $white-two;
        font-weight: normal;
        position: relative;

        &:before,
        &:after {
            content: "";
            position: absolute;
            /* fill vertically */
            top: 0;
            bottom: 0;
            width: 9600px;
            right: 100%;
            background: $white-two;
        }
        &:before {
            left: 100%;
        }
        // &:before {
        //     content: '';
        //     position: absolute;
        //     top: pxToRem(-100);
        //     left: pxToRem(-330);
        //     width: pxToRem(353);
        //     height: pxToRem(282);
        //     background-image: url(../mysource_files/bg-figure.svg);
        //     background-size: contain;
        // }

        p {
            margin: 0;
        }

        a {
            font-weight: bold;
            color: $dark-cyan--font;
            text-decoration: none;
            @include font-smoothing;

            &:hover {
                color: $dark-cyan--font;
                text-decoration: underline;
            }
        }

        &-bg {
            position: relative;
            &:before {
                content: '';
                width:  pxToRem(150);
                height: pxToRem(75);
                border-bottom-left-radius: pxToRem(150);
                border-bottom-right-radius: pxToRem(150);
                background-color: $dark-cyan;
                position:absolute;
                left: pxToRem(-40);
                top:  pxToRem(-110);
            }

            &:after {
                content: ' ';
                width:  pxToRem(250);
                height: pxToRem(125);
                border-top-left-radius: pxToRem(250);
                border-top-right-radius: pxToRem(250);
                background-color: $white-two;
                position: absolute;
                left: pxToRem(75);
                top:  pxToRem(-125);
            }
        }
    }

    .icon-chevron {
        background: $white;

        &:after {
            border-color: $turquoise-blue;
        }
    }
}
