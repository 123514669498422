/* Module: S */
.featured-banner {
    &__wrapper {
        max-width: $page__max-width;
        margin: 0 auto;
    }

    &__text {
    	width: 45%;
    	margin-left: 0;
        padding: pxToRem(148) pxToRem(40);
    }
}