/* Module: S */
.search-results__list {
    @extend %list-reset;
    padding: pxToRem(20) 0;

    .search-result__heading {
        font-size: pxToRem(18);
        font-weight: 500;
        line-height: 1.39;
        margin-top: 0;
        margin-bottom: .5rem;
        color: $dark-cyan;

        .fas {
            margin-left: pxToRem(10);
            font-size: 80%;
        }

        // &:after {
        //     content: '';
        //     border: solid $dark-cyan;
        //     border-width: 0 pxToRem(2) pxToRem(2) 0;
        //     display: inline-block;
        //     padding: pxToRem(4);
        //     @include sq-transform(rotate(-45deg));
        //     position: relative;
        //     top: -1px;
        //     margin-left: pxToRem(10);
        // }
    }
}

.search-result__item {
    margin-bottom: pxToRem(20);
    background-color: $white-four;
    border-radius: pxToRem(10);
    overflow: hidden;

    &:last-child {
        margin-bottom: 0;
    }
}

.search-result__link {
    position: relative;
    color: $greyish-brown;
    text-decoration: none;
    background-color: transparent;

    &:hover,
    &:focus {
        .search-result__heading {
            text-decoration: underline;
        }
    }
}

.search-result__desc {
    font-size: pxToRem(18);
    line-height: 1.64;
    margin-top: pxToRem(10);
    color: $greyish-brown;
}

.search-result__image-wrapper {
    width: 100%;
    height: pxToRem(192);
    overflow: hidden;
    position: relative;
}

.search-result__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    display: block;
    border: none;
    position: absolute;

}

.search-result__content {
    padding: pxToRem(28) pxToRem(26) pxToRem(28) pxToRem(26);
    @include sq-flex-shrink(1);
    width: 100%;
    position: relative;

    .search-result__best-bet & {
        border-bottom: 5px solid $orange;
    }
}

.search-result__type {
    font-size: pxToRem(18);
    line-height: 1.8;
    font-weight: bold;
    color: $dark-cyan;
    text-transform: uppercase;
    padding-bottom: pxToRem(5);
}

.search-result__type__label {
    display: inline-block;
}

.search-result__date {
    font-size: pxToRem(18);
    line-height: 1.8;
    color: $dark-cyan;
    @include font-smoothing;
}

.search-result__course {
    font-size: pxToRem(18);
    line-height: 1.8;
    color: $dark-cyan;
    @include font-smoothing;
}

.search-result__event-location {
    margin-bottom: .5rem;
}

.search-result__event-location {
    font-style: italic;
    font-weight: 400;
}

.search-result__url {
    line-height: 1rem;
    font-size: pxToRem(18);
    font-weight: 400;
    margin-top: 1rem;
    word-break: break-all;
}

.main__content {
    &-full {
        @include sq-order(-1);

        &.has__no-rhs {
            @include sq-order(0);
        }
    }
}

.rhs-filters {
    @extend %clearfix;

    ul {
        @extend %list-reset;
    }

    &__main {
       display: none;
    }

    &__toggle {
        color: $white;
        background-color: $dark-cyan;
        position: relative;
        width: 100%;
        display: block;
        font-size: pxToRem(16);
        font-weight: 500;
        margin-bottom: pxToRem(30);

        border: 0;
        text-align: left;
        padding: pxToRem(13) pxToRem(20);
        @include sq-transition(.2s all linear);
        @include font-smoothing();

        &:focus {
            outline: 0;
        }

        &::after {
            content: '';
            position: absolute;
            top: pxToRem(15);
            right: pxToRem(16);
            display: block;
            width: pxToRem(12);
            height: pxToRem(12);
            border-right: 2px solid $white;
            border-bottom: 2px solid $white;
            @include sq-transition(.2s transform linear, .2s border-color linear);
            @include sq-transform(rotate(45deg));
        }

        &[data-expanded='true'] {
            color: $white;
            background-color: $dark-cyan;
            &::after {
                top: pxToRem(21);
                @include sq-transform(rotate(225deg));
            }
        }
    }

    legend {
        font-size: pxToRem(25);
        font-weight: bold;
        color: $orange;
        background-color: transparent;
        padding: pxToRem(30) 0 pxToRem(18) 0;
        margin: 0;

        &:first-child {
            padding-top: 0;
        }
    }

    .form-fieldset__content {
        padding-bottom: pxToRem(20);
        display: block;
        margin: 0;
    }
}

.calendar__icon {
    display: block;
    fill: $color__tertiary;
    height: 22px;
    width: 26px;
}

// DATEPICKER
.ui-datepicker {
    display: none;
    background-color: #fff;
    padding: .5rem;
    border: 1px solid rgba(0, 0, 0, .25);
    box-shadow: 0 5px 15px -5px rgba(0, 0, 0, .5);
    margin-top: 3px;
}

.ui-datepicker-header {
    @include sq-flex-box;
    @include sq-justify-content(space-between);
    @include sq-align-items(center);
    margin-bottom: .4rem;
}

.ui-datepicker-prev,
.ui-datepicker-next {
    > .ui-icon {
        display: none;
    }
}

/* stylelint-disable */
.ui-datepicker-prev,
.ui-datepicker-next {
    cursor: pointer;
    display: block;
    height: 28px;
    width: 28px;
    background-color: #eee;
    border-radius: pxToRem(15);

    &::after {
        content: '';
        width: 0;
        height: 0;
        display: inline-block;
        border-style: solid;
        border-width: 7px 8px 7px 0;
        border-color: transparent #555 transparent transparent;
        vertical-align: middle;
        margin-top: 4px;
        margin-left: 9px;
    }

    &:hover,
    &:focus {
        background-color: darken(#eee, 6%);
    }
}
/* stylelint-enable */

.ui-datepicker-next {
    @include sq-order(3);

    &::after {
        border-width: 7px 0 7px 8px;
        border-color: transparent transparent transparent #555;
        margin-left: 10px;
    }
}

.ui-datepicker-calendar {
    width: 100%;
    min-width: pxToRem(200);

    td {
        padding: 1px;
    }

    thead {
        span {
            font-size: 80%;
            text-transform: uppercase;
            display: block;
            margin-bottom: .3rem;
        }
    }

    .ui-state-default {
        display: block;
        padding: .25rem .45rem;
        color: #555;
        font-size: pxToRem(15);
        font-weight: 400;
        text-align: center;
        text-decoration: none;
        background-color: #eee;
        border-radius: pxToRem(15);

        &.ui-state-hover {
            background-color: darken(#eee, 6%);
        }

        &.ui-state-active {
            color: #fff;
            background-color: $color__secondary;
        }
    }
}

.datepicker__wrap {
    position: relative;

    .form__control {
        padding-left: 2.5rem;
    }

    .datepicker__clear {
        position: absolute;
        top: 50%;
        right: 1rem;
        padding: 0;
        border: 0;
        height: 1.25rem;
        width: 1.25rem;
        color: #fff;
        background-color: $color__tertiary;
        display: none;
        border-radius: .2rem;
        line-height: 1;
        @include sq-transition(.2s background-color ease-in-out);
        @include sq-transform(translateY(-50%));

        &:hover,
        &:focus {
            outline: 0;
            background-color: darken($color__secondary, 5%);
        }
    }
}

.pagination {
    text-align: center;
    padding: pxToRem(20) 0 pxToRem(40) 0;

    &__item,
    li {
        display: inline-block;
        zoom: 1;
        padding: 0;
        font-size: pxToRem(14);
        font-weight: bold;
        color: $dark-cyan--font;
        padding: pxToRem(6) pxToRem(8);
        position: relative;

        &:first-child {
            //text-indent: -9999px;
            width: pxToRem(36);
            height: pxToRem(36);
            border: none;
            border-radius: 0;
            .gallery & {
                text-indent: inherit;
                width: inherit;
                height: inherit;

            }

            // &:before {
            //     content: '';
            //     position: absolute;
            //     top: pxToRem(12);
            //     left: pxToRem(15);
            //     border: solid $dark-cyan;
            //     border-width: 0 pxToRem(2) pxToRem(2) 0;
            //     display: inline-block;
            //     padding: pxToRem(5);
            //     @include sq-transform(rotate(135deg));
            //     .gallery & {
            //         display: none;
            //     }
            // }

            // a {
            //     position: relative;
            //     background-color: $white;
            //
            //     &:before {
            //         content: '';
            //         position: absolute;
            //         top: pxToRem(12);
            //         left: pxToRem(15);
            //         border: solid $dark-cyan--font;
            //         border-width: 0 pxToRem(2) pxToRem(2) 0;
            //         display: inline-block;
            //         padding: pxToRem(5);
            //         @include sq-transform(rotate(135deg));
            //         .gallery & {
            //             display: none;
            //         }
            //     }
            //
            //     &:hover {
            //         &:before {
            //             border-color: $dark-cyan;
            //         }
            //     }
            // }
        }

        &:last-child {
            //text-indent: -9999px;
            width: pxToRem(36);
            height: pxToRem(36);
            border: none;
            border-radius: 0;
            .gallery & {
                text-indent: inherit;
                width: inherit;
                height: inherit;
            }

            // &:before {
            //     content: '';
            //     position: absolute;
            //     top: pxToRem(12);
            //     right: pxToRem(15);
            //     border: solid $dark-cyan;
            //     border-width: 0 pxToRem(2) pxToRem(2) 0;
            //     display: inline-block;
            //     padding: pxToRem(5);
            //     @include sq-transform(rotate(-45deg));
            //     .gallery & {
            //         display: none;
            //     }
            // }

            // a {
            //     position: relative;
            //     background-color: $white;
            //
            //     &:before {
            //         content: '';
            //         position: absolute;
            //         top: pxToRem(12);
            //         right: pxToRem(15);
            //         border: solid $dark-cyan;
            //         border-width: 0 pxToRem(2) pxToRem(2) 0;
            //         display: inline-block;
            //         padding: pxToRem(5);
            //         @include sq-transform(rotate(-45deg));
            //         .gallery & {
            //             display: none;
            //         }
            //     }
            //
            //     &:hover {
            //         &:before {
            //             border-color: $dark-cyan;
            //         }
            //     }
            // }
        }

        &--current,
        &.slick-active {
            color: $dark-cyan;

            &:after {
                content: '';
                position: absolute;
                left: pxToRem(7);
                right: pxToRem(7);
                bottom: pxToRem(3);
                height: pxToRem(3);
                background-color: $dark-cyan;
            }
        }

        a {
            display: block;
            font-size: pxToRem(14);
            font-weight: bold;
            padding: pxToRem(6) pxToRem(8);
            margin: pxToRem(-6) pxToRem(-8);
            color: $dark-cyan;
            cursor: pointer;

            &:hover {
                color: $dark-cyan;
            }
        }
    }
}

.pagination {
    &__item {
        &:first-child ,
        &:last-child {

            a {
                color: $white-five;

                &:hover {
                    color: $dark-cyan--font;
                }
            }
        }

        &.is-disabled {
            a {
                color: $white-five;
                pointer-events: none !important;
                &:hover {
                    color: $white-five !important;
                }
            }
        }
    }
}

.search-page{
    &.search-page-single {
        .form__group-inline {
            input[type="text"] {
                font-size: pxToRem(18);
                font-weight: bold;
            }

            input[type="text"]::placeholder {
                font-size: pxToRem(18);
                font-weight: bold;
            }
        }
    }
}
