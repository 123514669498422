/* Module: S */
.main__footer {
    @include sq-flex-box;
    @include sq-flex-direction;
    @include sq-flex-direction(column);
    padding-top: pxToRem(40);
}

/* stylelint-disable */
.footer-links__wrapper {
    @include sq-flex-box;
    @include sq-flex-wrap(wrap);
    @include sq-flex-grow(1);
    @include sq-justify-content(flex-start);
    margin-left: auto;
    margin-right: auto;

    &:before {
        z-index: 9;
        content: '';
        position: absolute;
        top:  pxToRem(-80);
        left: pxToRem(-25);
        width: 140px;
        height: 70px;
        border-top-left-radius: pxToRem(140);
        border-top-right-radius: pxToRem(140);
        background-color: $dark-cyan;
    }

    &:after {
        z-index: 9;
        content: '';
        position: absolute;
        top:  pxToRem(-31);
        left: pxToRem(-102);
        width: 80px;
        height: 40px;
        border-bottom-left-radius: pxToRem(80);
        border-bottom-right-radius: pxToRem(80);
        background-color: $white;
    }
}

/* stylelint-enable */

.footer-links__column {
    width: auto;
    @include sq-flex-basis(auto);
    margin-left: pxToRem(80);
    margin-right: 0;
    margin-bottom: 0;

    &:first-child {
        width: 35%;
        margin-left: 0;
    }
}

.footer-links__logo {
    padding: 0;
}

.footer-links__column {
    padding-top: pxToRem(18);

    &:nth-of-type(2) {
        margin-left: pxToRem(67);
    }

    &:nth-of-type(3) {
        margin-left: pxToRem(84);
    }
    &:last-child {
        padding-top: 0;
        margin-left: 30%;
    }
    .footer-links__list {
        padding: 0;
    }
}

.footer-link,
.footer-contact__heading {
    font-size: pxToRem(12);
}

.footer-link{
    margin-bottom: pxToRem(0);
}

.footer-contact__heading {
    color: $white;
    font-weight: bold;
}

.footer-social__wrapper {
    margin-top: pxToRem(60);
}
