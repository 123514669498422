/* Module: S */

.quicklinks {
    position: relative;
    border-radius: pxToRem(10);
    overflow: hidden;
    &__wrapper {
        padding: pxToRem(15);
        background: $white-two;
    }
}


.quicklinks__heading {
    cursor: pointer;
    position: relative;
    background-color: $dark-cyan;
    font-size: pxToRem(18);
    font-weight: bold;
    color: $white;
    margin-top: 0;
    margin-bottom: 0;
    padding: pxToRem(13) pxToRem(46) pxToRem(13) pxToRem(20);
    @include font-smoothing;

    &::after {
        content: '';
        position: absolute;
        right: 1.25rem;
        top: 50%;
        margin-top: -8px;
        display: block;
        height: 10px;
        width: 10px;
        border-right: 2px solid $white;
        border-bottom: 2px solid $white;
        @include sq-transform(rotate(45deg));
        @include sq-transition(.15s transform linear);
    }

    &[data-expanded="true"] {
        &::after {
            margin-top: -2px;
            @include sq-transform(rotate(-135deg));
        }
    }
}

.quicklinks__container {
    display: none;
}

.quicklinks__block {
    position: relative;
    z-index: 1;

    &.active {
        //border-bottom: 1px solid $white-three;

        &:last-child {
            border-bottom: 0;
        }
    }

    .quicklinks__heading {
        top: 0;
        left: 0;
        width: 100%;
        display: block;
        text-align: left;
        font-weight: normal;
        font-size: pxToRem(16);
        padding: pxToRem(10) pxToRem(46) pxToRem(10) pxToRem(20);
        background-color: $white;
        color: $dark-cyan;
        border: 0;
        //border-bottom: 1px solid $white-three;
        @include sq-transition(.2s background-color linear);

        &:hover,
        &:focus {
            outline: 0;
            background-color: $white-three;
        }

        &::after {
            content: '';
            position: absolute;
            right: 1.25rem;
            top: 50%;
            margin-top: -8px;
            display: block;
            height: 10px;
            width: 10px;
            border-right: 2px solid $dark-cyan;
            border-bottom: 2px solid $dark-cyan;
        }
    }

    &.active {
        .quicklinks__heading {
            border-bottom: 0;

            &::after {
                margin-top: -2px;
                @include sq-transform(rotate(-135deg));
            }
        }
    }
}

.quicklinks__content {
    display: none;
    padding: pxToRem(10) pxToRem(28);
    background-color: $white;
}

.quicklinks__link {
    line-height: 2;
    display: block;
    text-decoration: none;
    @include sq-transition(.15s color ease-in-out);
    color: $black;

    &:focus, &:hover {
        outline: 0;
        text-decoration: underline;
        color: $black;
    }
}

.quicklinks__link--standalone {
    font-size: pxToRem(16);
    line-height: 1.4;
    padding: pxToRem(10) pxToRem(46) pxToRem(10) pxToRem(20);
    background-color: $white;
}