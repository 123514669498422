// ACCORDION SETTINGS
$accordion-heading__color: $color__tertiary;
$accordion-heading__color--hover: #fff;
$accordion-heading__color--active: $accordion-heading__color;
$accordion-heading__bg: #fff;
$accordion-heading__bg--hover: $color__tertiary;
$accordion-heading__bg--active: #fff;
$accordion-heading-arrow__bg: #0088a6;
$accordion-heading-arrow__bg--active: $accordion-heading__color;
$accordion-content__bg: #fff;
$accordion-separator__color: #f0f0f0;

// TABS SETTINGS
$tabs-tab__bg: $color__tertiary;
$tabs-tab__bg--hover: darken($tabs-tab__bg, 5%);
$tabs-tab__bg--active: #fff;
$tabs-tab__color: #fff;
$tabs-tab__color--hover: $tabs-tab__color;
$tabs-tab__color--active: $color__tertiary;
$tabs-content__bg: #fff;

// GALLERY SETTINGS
$gallery__max-width: 50rem; // 800px
$gallery__bg: lighten($color__tertiary, 30%);
$gallery-thumbnail-arrows__bg: $color__secondary;
$gallery-description__color: inherit;
$gallery-description__bg: lighten($color__tertiary, 25%);

// TABLE OF CONTENT
$toc-box__max-width: 250px;
$toc-box__border: 1px solid $color__secondary;
$toc-heading__color: $color__tertiary;
$toc-link__color: #58595b;
$toc-link__color--hover: $color__primary;
