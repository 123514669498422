/* Module: S */
.featured-images {
    background-color: transparent;
    padding: pxToRem(76) 0 pxToRem(50) 0;

    &__wrapper {
	    position: relative;
	    padding: 0 pxToRem(12);
        @include dot-bg ($white, $white-three, before);
        @include dot-bg ($white, $white-three, after);
        &:before,
        &:after {
            top: pxToRem(-160);
        }
    }

    &__item {
        width: 25%;
        position: relative;
        margin-top: pxToRem(28);

        &:first-of-type {
            margin-top: pxToRem(28);
        }
    }

}
