@font-face {
  font-family: 'GothamRounded-Book';
  src: url('fonts/GothamRounded-Book.eot');
  src: url('fonts/GothamRounded-Book.woff2') format('woff2'),
       url('fonts/GothamRounded-Book.woff') format('woff'),
       url('fonts/GothamRounded-Book.ttf') format('truetype'),
       url('fonts/GothamRounded-Book.svg#GothamRounded-Book') format('svg'),
       url('fonts/GothamRounded-Book.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@import 'variables.scss';
@import 'functions.scss';
@import 'mixins.scss';
@import 'placeholders.scss';

