/* Module: S */
/* stylelint-disable */
.landscape-carousel__caption-wrapper {
    @include sq-flex-box;
    @include sq-justify-content(flex-start);
    width: 100%;
    max-width: $page__max-width;
    margin: 0 auto;
    padding-left: 2rem;
    padding-right: 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    @include sq-transform(translate(-50%, -50%));
}
/* stylelint-enable */

.landscape-carousel__controls {
    top: auto;
    bottom: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.landscape-carousel__image {
    height: pxToRem(425);
}

.landscape-carousel__caption {
    width: 100%;
    max-width: calc(100% - 22rem);
    padding: pxToRem(30) 0;
}

.landscape-carousel__caption-link {
    margin-top: pxToRem(26);
    font-size: pxToRem(20);
}

.landscape-carousel__caption-heading {
    font-size: pxToRem(40);
    font-weight: normal;
}

.landscape-carousel__item {
    background-color: transparent;
    &:after {
        background: #002664; /* Old browsers */
        background: -webkit-linear-gradient(left, rgba(17, 110, 117, 1) 0%, rgba(17, 110, 117, 0.4) 50%, transparent 100%);
        background: -o-linear-gradient(left, #002664 0%, rgba(17, 110, 117, 0.4) 50%, transparent 100%);
        background: linear-gradient(to right, #002664 0%, rgba(17, 110, 117, 0.4) 50%, transparent 100%); /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */ /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002664', endColorstr='transparent',GradientType=1 ); /* IE6-9 */
    }
}

