/* Module: S */
.featured-display {
    padding: 0 pxToRem(32);

    &__wrapper {
        padding: pxToRem(100) 0;
        @include dot-bg ($white, $white-three, before);
        @include dot-bg ($white, $white-three, after);
    }

    &__item {
        width: 25%;
        padding: pxToRem(12) pxToRem(40);

        h2 {
            margin: 0;
            margin-top: pxToRem(10);
            text-align: left;
        }

        // Removed for: HETIPW-102
        // h3 {
        //     font-size: pxToRem(115);

        //     & ~ p {
        //         padding-top: pxToRem(24);
        //     }
        // }

        p {
            padding-top: pxToRem(16);
        }

        &:after {
            display: none;
        }
    }

    &__image {
        border: none;
        padding: 0;
        text-align: center;
    }
}
