/* Module: S */
.featured-tiles {
    padding-bottom: pxToRem(30);

    &__inner {
    	padding:  0 pxToRem(32);
    }

    &__item {
        width: 25%;
    }

    &-landing {
    	.featured-tiles {
		    &__item {
		        width: 33.333%;
		    }    		
    	}
    }    
    &__wrapper {
        max-width: $page__max-width;
        margin: 0 auto;
        position: relative;

        &:before {
            z-index: 9;
            content: '';
            position: absolute;
            bottom: -163px;
            left: -195px;
            width: 200px;
            height: 200px;
            border-radius: 100%;
            background-color: white;
        }

        &:after {
            z-index: 9;
            content: '';
            position: absolute;
            bottom: -56px;
            left: -284px;
            width: 100px;
            height: 100px;
            border-radius: 100%;
            background-color: $white;
        }
    }
}
