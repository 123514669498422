/* Module: S */
.news {

    &__wrapper {
		padding:  pxToRem(10) pxToRem(20);
    }

    &__column {
        margin: 0 pxToRem(-20);
		padding:  pxToRem(30) pxToRem(4);
    }

    &__blocks {
    	.news {
		    &__item {
		        width: 50%;

		        &:nth-child(2n+1){
				    clear: left;
				}
		    }
    	}
    }

}