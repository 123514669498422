/* Module: S */
.featured-banner {
    background-size: cover;
    background-position: center;

    &__wrapper {
        max-width: $page__max-width;
        margin: 0 auto;
    }

    &__text {
        padding: pxToRem(100) pxToRem(20);
        font-size: pxToRem(25);
        font-family: $secondary__font-family;
        font-weight: normal;
        line-height: 1.35;
        color: $white;
        @include font-smoothing;

        a {
            color: $white;

            &:hover {
                color: $white;
            }
        }
    }
}