/* Module: S */
.landing-panel {
  color: $landing-panel__color;
  background-color: $landing-panel__bg;
  border: $landing-panel__border;
  margin-top: $landing-panel__space-between;
  padding: 1rem;
  @include sq-transition(0.2s all ease-in-out);

  &:first-child {
    margin-top: 0;
  }

  &:hover,
  &:focus {
    color: $landing-panel__color--hover;
    background-color: $landing-panel__bg--hover;
    border: $landing-panel__border--hover;
  }
}

.landing-panel__link {
  color: $landing-panel__color;
  text-decoration: none;
}

.landing-panel__heading {
  margin-top: 0;
  margin-bottom: 0.75rem;
}

.landing-panel__image {
  display: block;
  margin-bottom: 1rem;
}

.landing-image__container {
  box-shadow: inset 0px -120px 85px -50px rgba(0, 0, 0, 0.7);
  max-width: pxToRem(1440);
  margin: 0 auto;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #146cfd; /* Old browsers */
    background: -webkit-linear-gradient(
      bottom,
      rgba(17, 110, 117, 1) 0%,
      rgba(17, 110, 117, 0.6) 50%,
      transparent 100%
    );
    background: -o-linear-gradient(
      bottom,
      #146cfd 0%,
      rgba(17, 110, 117, 0.6) 50%,
      transparent 100%
    );
    background: linear-gradient(
      to top,
      #146cfd 0%,
      rgba(17, 110, 117, 0.6) 50%,
      transparent 100%
    ); /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */ /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#146cfd', endColorstr='transparent',GradientType=1 ); /* IE6-9 */
  }

  img {
    opacity: 0;
  }
}

.landing-image {
  position: relative;
  display: block;
  height: pxToRem(187);
  width: 100%;
  object-fit: cover;
  //z-index: -1; //HETIPW-43 not sure why this is set to -1. In order to have image displayed in Edge, have to remove it.

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    z-index: inherit;
  }
}

.landing__content {
  h1,
  h2,
  h3 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  + .panels__container {
    margin-top: 1.5rem;
  }
}
