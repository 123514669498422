.wrapper {
  padding-left: pxToRem(32);
  padding-right: pxToRem(32);
}

.wrapper,
.main-content__wrapper,
.main__form form {
  @include sq-flex(1 1 auto);
  @include sq-flex-direction(row);
  @include sq-align-items(flex-start);
}
.main-column__wrapper {
  min-width: 1px;
  @include sq-flex(0 1 100%);
}
.main-content__wrapper,
.main__form form {
  @include sq-flex-wrap(wrap);
}
.main__content {
  width: auto;
  min-width: 1px;
  @include sq-flex(1 0 0%);
}
_::-webkit-full-page-media,
_:future,
:root .tool-box {
  @include sq-flex-basis(100%);
}
//
_::-webkit-full-page-media,
_:future,
:root .tool-box + .main__content {
  @include sq-flex-basis(1px);
}

.lhs__menu-toggle {
  display: none;
}

.lhs__menu-list:not(.menu__level-2, .menu__level-3, .menu__level-4, .menu__level-5) {
  display: inherit;
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}

.tools-social {
  @include sq-flex-box;

  .landing-image__container + .wrapper & {
    margin-top: 1rem;
    position: absolute;
    right: pxToRem(20);
    bottom: pxToRem(-60);
  }
}

.tool-box {
  @include sq-align-items(flex-start);
  min-height: pxToRem(66);
  position: relative;

  .landing-image__container + .wrapper & {
    margin-top: pxToRem(-70);
    margin-bottom: pxToRem(30);
  }

  &.has__no-rhs:not(.has_search) {
    margin-bottom: pxToRem(92);
  }
}

.main__content {
  @include sq-flex(1 0 0%);
}

.responsive__table {
  table {
    min-width: auto;
    th {
      white-space: normal;
    }
  }
}

/*
--------------------
Modules
--------------------
*/
