/* Module: S */
.quicklinks__wrapper {
    width: 100%;
    max-width: $page__max-width;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    bottom: 1.75rem;
    @include sq-transform(translateX(-50%));
    padding: 0;

}

h2.quicklinks__heading {
    cursor: default;

    &:after {
        content: none;
    }
}

.quicklinks__container {
    display: inherit;
    background-color: $white;
}

.quicklinks {
    position: absolute;
    bottom: 0;
    right: 2rem;
    max-width: pxToRem(230);
    width: 100%;
    box-shadow: 0px 3px 40px 0 rgba(0, 0, 0, 0.4);
}

