/* stylelint-disable */

// Convert a pixel value to EM units
@function pxToEm($pixel) {
    @return $pixel/$base__font-pixel + em;
}

// Convert a pixel value to REM units
@function pxToRem($pixel) {
    @return $pixel/$base__font-pixel + rem;
}

// Strip unit taken from foundation
@function stripUnit($num) {
    @return $num / ($num * 0 + 1);
}

// String replace
@function str-replace($string, $search, $replace: "") {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

// Convert Photoshop Letter spacing to EM
@function photoshopLetterspacingToEms($val) {
     @return ( $val / 1000 ) * 1em;
}

// Convert Photoshop Letter spacing to PX
@function photoshopLetterspacingToPixels($val, $font-size) {
     @return ( $val * $font-size / 1000 ) * 1px;
}

/* stylelint-enable */
