/* Module: S */
.event__info-inner {
    @include sq-flex-box;
    @include sq-justify-content(space-between);
    @include sq-align-items(flex-start);

    h1,
    h2 {
        margin-right: .5rem;
        margin-bottom: 0;
    }
}

.event__info-list {
    -webkit-columns: 2;
    -moz-columns: 2;
    columns: 2;
    -webkit-column-gap: 3rem;
    -moz-column-gap: 3rem;
    column-gap: 3rem;

    li {
        margin-bottom: .5rem;
    }

    strong {
        display: block;
    }
}
