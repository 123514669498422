/* Module: S */
//Icons
.close-icon {
    display: none;
    .is-active & {
        display: block;
    }
}

.search-icon,
.menu-icon {
    display: block;
    .is-active & {
        display: none;
    }
}

.menu-icon,
.close-icon {
    fill: $black;
}

.search-icon {
    fill: $white-three;
    background-color: $white;
    padding: pxToRem(10);
    overflow: visible;
    border-radius: 50%;
    width: 150%;
    height: 150%;
    transform: translateX(-7px) translateY(-7px);

}

//Header
.main-header {
    background: #cdd3d6;
    position: relative;

    .is-mobile & {
        z-index: 4;
    }
}

.top-nav {
    margin-left: auto;
    margin-right: auto;
    max-width: ($page__max-width - 4rem);
    @extend %clearfix;
}

.top-nav__wrapper {
    background-color: $white;
}

.top-nav__list {
    margin: 0;
    padding: 0;
    list-style: none;
    float: right;

    a {
        font-size: pxToRem(14);
        line-height: normal;
        color: $dark-cyan;
        text-decoration: none;
        padding: pxToRem(10) pxToRem(20);
        display: block;
        @include font-smoothing;

        &:hover,
        &:focus  {
            text-decoration: underline;
        }
    }

    li {
        float: left;

        &.highlighted {
            margin-left: pxToRem(10);
            a {
                background-color: $dark-cyan;
                color: $white;
                //overflow: hidden;
                border-radius: pxToRem(25) 0 0 pxToRem(25);
                position: relative;
                &:before {
                    content: "";
                    position: absolute;
                    z-index:  10;
                    /* fill vertically */
                    top: 0;
                    bottom: 0;
                    width: auto;
                    left: 100%;
                    background: $dark-cyan;
                }
            }
        }
    }
}

.main-header__wrapper {
    @include sq-flex-box;
    @include sq-align-items(center);
    @include sq-justify-content(space-between);
    padding: 0 pxToRem(12) 0 pxToRem(20);

}
.main-header__wrapper:before {
    background: linear-gradient(90deg, #002664 30px, transparent 1%) center top, linear-gradient(#002664 30px, transparent 1%) center top, #aaa!important; 
    background-size: 32px 32px !important;
}
.main-header__wrapper:after {
    background: linear-gradient(90deg, #cdd3d6 30px, transparent 1%) center top, linear-gradient(#cdd3d6 30px, transparent 1%) center top, #aaa!important; 
    background-size: 32px 32px !important;
}
.main-header__wrapper .logo__link:after {
    background: linear-gradient(90deg, #cdd3d6 30px, transparent 1%) center top, linear-gradient(#cdd3d6 30px, transparent 1%) center top, #aaa!important; 
    background-size: 32px 32px !important;
}

.main-nav__wrapper {
    @include sq-flex-box;
    @include sq-align-items(center);
    @include sq-justify-content(space-between);
}

.logo__link {
    //display: inline-block;
    margin-right: auto;
    background: #002664; //$turquoise-blue;
    padding: pxToRem(17) pxToRem(15) pxToRem(16) pxToRem(20);
    margin-left: pxToRem(-20);
    color: $white;
    text-decoration: none;
    border-radius: 0 200px 200px 0;
    font-size: pxToRem(19);
    width: pxToRem(161);
    line-height: pxToRem(23);
    font-family: $secondary__font-family;
    //@include dot-bg ($turquoise-blue, $turquoise-blue, before);

}

.logo__image {
    display: block;
    height: 100%;
    vertical-align: top;
}

.main-nav {
    display: none;
    width: 100%;
}

.main-nav__list {
    @include sq-flex-box;
    @include sq-align-items(center);
    @include sq-justify-content(space-between);
    list-style-type: none;
    margin: 0;
    padding: pxToRem(14) pxToRem(22);
}

.main-nav__item {
    position: relative;
    @include sq-flex-box;
    @include sq-align-items(center);
    min-height: pxToRem(64);
    width: 100%;

    &::before {
        display: block;
        height: .5rem;
        left: 0;
        top: 0;
        margin: 0 auto;
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        will-change: transform;
        transition: transform .3s ease;
    }

    &:hover {
        &::before {
            transform: scaleX(1);
        }
    }
}

.main-nav__link {
    font-family: $secondary__font-family;
    font-weight: 500;
    .active & {
       // font-weight: 600;
    }
}

.header-logos__wrapper {
    display: none;
    margin-left: pxToRem(25);
    img {
        max-height: 56px;

        &:last-of-type {
            margin-left: pxToRem(5);
        }
    }
}

.header-search__form {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: pxToRem(20);
    background-color: rgba(0, 0, 0, 0.6);
    display: none;
    .sr-only {
        color: #3e7e87;
    }

    @include placeholder-color($dark-cyan);
}

.header-search__form-inner {
    position: relative;
}

.header-search__input {
    border: none;
    //border-bottom: 1px solid rgba($black-four, 0.29);
    padding: pxToRem(20) pxToRem(60) pxToRem(20) pxToRem(20);
    border-radius: 30px;
    vertical-align: top;
    width: 100%;
    background: $white;
    font-size: pxToRem(18);
    //font-weight: bold;
    color: $dark-cyan;
    @include sq-transition(.15s background-color linear);
    @include placeholder {
        font-weight: normal;
    }

    // -webkit-box-shadow: 0 pxToRem(10) pxToRem(20) 0 rgba($black-four, 0.3);
    // -moz-box-shadow: 0 pxToRem(10) pxToRem(20) 0 rgba($black-four, 0.3);
    // box-shadow: 0 pxToRem(10) pxToRem(20) 0 rgba($black-four, 0.3);

    &:focus,
    &:active {
        outline: 0;
    }
}

.header-search__submit-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
    width: 40px;
    height: 40px;
    background: none;
    border: none;
    padding: .5rem;

    svg {
        fill: $white-three;
        background-color: $white;
        padding: 0;
        overflow: visible;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        margin: pxToRem(7) 0 0 pxToRem(7);
        &:focus,
        &:hover {
            fill: $dark-cyan;
        }
    }

    background: url("mysource_files/search.svg");
}


//mobile buttons
.main-menu__btn,
.header-search__mobile-btn {
    border: none;
    width: pxToRem(40);
    height: pxToRem(40);
    background: none;
    padding: pxToRem(8);
    margin-left: pxToRem(15);
    display: inline-block;
}

.main-menu__btn {
    svg {
        width: pxToRem(30);
    }
}

//Mega menu
.mega-menu__wrapper {
    padding: pxToRem(5) 0 pxToRem(20) 0;
    position: absolute;
    width: 100%;
    display: none;
    height: auto;
    background: $white;

    -webkit-box-shadow: 0 pxToRem(10) pxToRem(20) 0 rgba($black-four, 0.3);
    -moz-box-shadow: 0 pxToRem(10) pxToRem(20) 0 rgba($black-four, 0.3);
    box-shadow: 0 pxToRem(10) pxToRem(20) 0 rgba($black-four, 0.3);
}

.menu-arrow {
    display: block;
    top: 0;
    height: 100%;
    position: absolute;
    right: 0;
    width: pxToRem(60);
    @include sq-transition(.3s all ease-in-out);

    &:hover,
    &:focus {
        outline: 0;
    }

    &::after {
        border-left: 2px solid $black-four;
        border-top: 2px solid $black-four;
        content: '';
        position: absolute;
        display: block;
        height: pxToRem(9);
        left: 50%;
        top: 50%;
        width: pxToRem(9);
        margin-top: pxToRem(-5);
        @include sq-transform(rotate(-135deg));
        @include sq-transition(.15s transform linear);

        .lvl-2 & {
            border-left: 2px solid #fff;
            border-top: 2px solid #fff;
        }
    }

    .is-expanded > .mega-menu__feature &,
    .is-expanded > .mega-menu__sub-nav-title & {
        &::after {
            margin-top: pxToRem(-2);
            @include sq-transform(rotate(45deg));
        }
    }
}

.mega-menu__close-button {
    display: none;
}

.mega-menu__item {
    padding: 0;
    opacity: 1;
    position: relative;

}

.mega-menu__feature {
    width: 100%;
    padding: 0;
    float: none;
}

.mega-menu__feature-description {
    display: none;
    margin-top: 0;
    margin-bottom: 0;
    font-size: pxToRem(16);
    font-family: $secondary__font-family;
}

.mega-menu__feature-title,
.mega-menu__sub-nav-title {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0 pxToRem(26) 0 pxToRem(26);
}

.mega-menu__feature-link {
    position: relative;
    display: block;
    font-size: pxToRem(16);
    font-weight: normal;
    font-family: $secondary__font-family;
    line-height: 1.47;
    padding: pxToRem(18) 0 pxToRem(8) 0;
    border-bottom: 1px solid rgba($black-four, 0.29);
    margin-bottom: pxToRem(10);
    text-decoration: none;
    color: $black-four;

    &:hover,
    &:focus {
        //background-color: rgba($black-four, 0.1);
        outline: 0 none;
        color: $black-three;
    }
}

.mega-menu__navs-wrapper {
    width: 100%;
    display: none;
    overflow: hidden;
    background: $black-four;
    padding-top: 1rem;
    padding-bottom: 1rem;
    @include font-smoothing;

    .mega-menu__list {
        width: 100%;
        padding: 0;
    }
}

.mega-menu__list {
    list-style: none;
    margin: 0;
    padding: 0;

    .lvl-2 & {
        display: none;
        padding-top: .75rem;
        padding-bottom: .75rem;

        .mega-menu__sub-nav-link {
            padding-left: pxToRem(26);
            color: $palest-grey;
        }
    }

    .lvl-2 {
        .mega-menu__sub-nav-link {
            font-weight: 500;
        }
    }
}

.mega-menu__sub-nav-link,
.mega-menu__sub-nav-text {
    position: relative;
    display: block;
    font-size: pxToRem(16);
    font-weight: normal;
    font-family: $secondary__font-family;
    padding: pxToRem(10) pxToRem(48) pxToRem(10) 0;
    color: #fff;
    text-decoration: none;

    &:hover,
    &:focus {
        color: #fff;
        outline: 0;
    }
}

.mega-menu__sub-nav-text {
    &:hover {
        text-decoration: none;
    }
}

.menu-overlay {
    background: transparent;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 4;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    @include sq-transition(.3s opacity ease-in-out, .3s visibility ease-in-out);

    &.show {
        opacity: 1;
        visibility: visible;
    }
}
