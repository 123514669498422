/* Module: Magnific popup */
.popup {

}

.popup__list {
    @extend %list-reset;
}

.popup__item {

}