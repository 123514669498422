/* Module: S */
.social-list {
    padding:  pxToRem(60) pxToRem(20);

    &__wrapper {
        margin: 0 pxToRem(-20);

    }

    &__item {
        width: 50%;
    }
}