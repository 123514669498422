/* Module: S */


.featured-people {
    padding: pxToRem(24) pxToRem(20) 0 pxToRem(20);

    &:before {
        top: pxToRem(-160);
        left: pxToRem(-330);
        width: pxToRem(353);

    }

    &:after {
        bottom: pxToRem(130);
        right: pxToRem(-80);

    }
    &__item {
        width: 50%;
        //height: pxToRem(293);

	    &.active {
            position: static;
	    	> a,
            .featured-people__avatar {
	    		position: absolute;
	    		top: 0;
	    		left: 0;
	    		width: 50%;
	    		z-index: 9;
                height: pxToRem(293);
                padding-bottom: 0;
                border-radius: pxToRem(15);
	    	}

            .featured-people__info-wrap {
                height: auto;
                top: pxToRem(300);
            }

	        .featured-people {
	            &__content {
	                display: block;
	            }

	        }
	    }
        &.is-hidden {
            visibility: hidden;
        }
    }

    &__content {
        position: absolute;
        top: 0;
        left: 50%;
        width: 50%;
        bottom: pxToRem(30);
		z-index: 9;
        padding: pxToRem(10) pxToRem(60) pxToRem(26) pxToRem(60);
        margin-left: pxToRem(20);
        margin-right: pxToRem(20);
        margin-top: 0;
        overflow: hidden;

        &:after {
        	content: '';
        	position: absolute;
        	background-color: $white;
		    width: 100%;
		    height: 50%;
		    top: 50%;
		    left: -100%;
        }
    }

    &__link {
        padding: pxToRem(48) pxToRem(20);

        &-bg {
            &:before {
                left: pxToRem(-30);
                top:  pxToRem(-90);
            }

            &:after {
                left: pxToRem(96);
            }
        }
    }
}
