/* Module: S */
/* Typeahead */

$borders: $turquoise-blue;
$font: $black;
$background: #f4f4f4;
$background_active: #eaeaea;
$default-transition: .4s cubic-bezier(.77, 0, .175, 1);

.twitter-typeahead {
    width: 100%;
}

.search-page {
    position: relative;
    @include placeholder-color($dark-cyan);
    font-weight: bold;
    margin: pxToRem(10) 0 pxToRem(30) 0;

    &.search-page-single {
        margin-top: pxToRem(30);

        .search__summary {
            text-align: right;
            font-weight: normal;
        }
    }

    input[type="text"] {
        padding: pxToRem(20) pxToRem(54) pxToRem(20) pxToRem(24);
        color: $dark-cyan;
        background-color: rgba($white-four, 0.8);
        border: 0 none;
        border-radius: pxToRem(30);
    }

    button {
        background-color: transparent;
        position: absolute;
        top: 0;
        bottom: 0;
        right: pxToRem(20);
        border: none;
        padding: 0 pxToRem(18);
        position: absolute;
        top: 0;
        right: 0;

        svg {
            fill: $dark-cyan;
            width: pxToRem(30);
            height: pxToRem(32);
            background: none;
            padding: 0;
            margin-top: pxToRem(13);
        }
    }

    label {
        font-size: pxToRem(18);
    }
}

.tt-menu {
    background-color: $background;
    color: $font;
    text-align: left;
    width: 100%;

    .rgba & {
        background-color: $background;
    }
    .is-opened & {
        margin-top: 0;
    }
}


.form__control.typeahead {
  border-width: 1px;
  height: 100%;

  &:focus {
    border-width: 1px;
  }
}

.tt-selectable {
    @include sq-transition(border-color $default-transition, background-color $default-transition);
    cursor: pointer;
    font-size: pxToRem(16);
    padding: pxToRem(10) pxToRem(16);
    font-weight: 600;

    &:not(:last-child) {
        border-color: $turquoise-blue;
        border-style: solid;
        border-width: 0 0 pxToRem(1);
        .rgba & {
            border-color: $borders;
        }
    }
    &:focus,
    &:hover,
    &.tt-cursor {
        color: $font;
        background-color: $background_active;
    }
}
