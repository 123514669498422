/* Module: S */
.social-list {
    padding:  pxToRem(60) pxToRem(32);   
    @include dot-bg ($white, $white-three, before);
    @include dot-bg ($white, $white-three, after);
    &:before,
    &:after {
        top: pxToRem(-55);
    }

    &__item {
        width: 25%;
    }
    &__wrapper {
        position: relative;
    
        &:before {
            content: '';
            position: absolute;
            bottom: pxToRem(-160);
            right: pxToRem(-400);
            width: pxToRem(353);
            height: pxToRem(282);
            background-image: url(../mysource_files/HETI_logo_blue.svg);
        	background-repeat: no-repeat;
            z-index: 9;
        }
    }
    .featured-images + & {
		padding-top:  0;
    }
}