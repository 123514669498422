/* Module: S */
.featured-tiles {
    padding:  pxToRem(14) 0;
    padding-bottom: pxToRem(60);

    &__inner {
        margin: 0 pxToRem(-20);
    	padding:  0 pxToRem(20);
    }

    &__item {
        width: 50%;
    }

    &-landing {
        padding: pxToRem(40) 0;

        .featured-tiles {
            &__inner {
                padding: 0;
            }
        }
    }
}

.section {
    h2 {
        font-size: pxToRem(40) !important;
    }
}

h2.section-title {
    font-size: pxToRem(40) !important;
}
