/* Module: S */
.main-header__wrapper {
  margin: 0 auto;
  max-width: $page__max-width;
  padding: 0 pxToRem(32) 0 pxToRem(32);
  @include dot-bg($dot-color: #aaa);
  @include dot-bg($turquoise-blue, #666, before);
  z-index: 10;
}

.header-logos__wrapper {
  display: block;

  .header-logos__heti {
    max-height: 56px;
    margin-bottom: 2px;
  }
}

.header-search__wrapper {
  position: relative;
  width: pxToRem(220);
}

.header-search__input {
  position: relative;
  //border-bottom: 3px solid $turquoise-blue;
  padding: pxToRem(8) pxToRem(35) pxToRem(8) pxToRem(18);
  //background: transparent;
  color: $dark-cyan;
  font-weight: normal;
}

.header-search__form {
  padding: 0;
  background-color: transparent;
  @include placeholder-color($dark-cyan);
  @include font-smoothing;
}

.header-search__submit-button {
  top: 0;
  right: pxToRem(0);
  padding: pxToRem(10);
  @include ie-transparent();
  @include sq-transform(none);

  svg {
    fill: $white-three;
  }
}

.header-search__form {
  position: static;
  @include sq-flex-box();
}

.main-nav__wrapper {
  background-color: $white;
}

.logo__link {
  margin-left: pxToRem(-32);
  @include dot-bg($dot-color: #aaa);
  &:after {
    pointer-events: none;
    width: 100%;
    background-position: 32px 0;
  }
}

@media all and (min-width: 1440px) {
  .logo__link {
    &:after {
      width: 118%;
    }
  }
}

.main-nav {
  display: block;
  margin: 0 auto;
  max-width: $page__max-width;
  position: relative;

  &:before {
    z-index: 9; //< main-header__wrapper
    content: "";
    position: absolute;
    top: -138px;
    right: -195px;
    width: 200px;
    height: 200px;
    border-radius: 100%;
    background-color: #cdd3d6;
  }

  &:after {
    z-index: 11; //> main-header__wrapper
    content: "";
    position: absolute;
    top: -37px;
    right: -286px;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background-color: $white;
  }
}

.main-nav__item {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border-bottom: 1px dotted;
  overflow: hidden;

  // &:first-child {
  //     margin-left: 0;
  // }
}

.main-nav__link {
  color: $black;
  text-decoration: none;
  font-size: pxToRem(16);
  padding: 0;
  display: block;
  @include sq-transition(0.2s color linear);

  &:hover,
  &:focus {
    color: $pumpkin;
  }

  &::before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: pxToRem(-24);
    display: block;
    height: pxToRem(20);
    width: pxToRem(20);
    border-right: pxToRem(20) solid $white;
    border-bottom: pxToRem(20) solid $white;
    opacity: 0;
    z-index: 2;
    @include sq-transform(translate(-50%, 0) rotate(-135deg));
  }

  &.main-nav__link--active {
    &::before {
      opacity: 1;
    }
  }
}

.menu-arrow {
  display: none;
}

.mega-menu__wrapper {
  padding: pxToRem(36) 0;
  display: block;
  width: 100%;
  height: 400px;
  background: $black;
  overflow: hidden;
  box-shadow: none;
}

.mega-menu__items {
  max-width: $page__max-width;
  margin: 0 auto;
  position: relative;
}
.mega-menu__close-button {
  background: none;
  border: none;
  padding: 0;
  display: block;
  height: 18px;
  width: 18px;
  position: absolute;
  right: 2rem;
  top: 0;
  .close-icon {
    display: block;
    fill: $white-three;
  }
}

.mega-menu__item {
  position: absolute;
  left: 50%;
  width: 100%;
  max-width: $page__max-width;
  padding-left: 2rem;
  padding-right: 3.5rem;
  opacity: 0;
  visibility: hidden;
  border-bottom: 0;
  @include sq-transform(translateX(-50%));
  @include sq-transition(0.3s ease-in-out opacity, 0.3s ease-in-out visibility);

  &.mega-menu__item--visible {
    opacity: 1;
    visibility: visible;
  }
}

.mega-menu__feature {
  width: 30%;
  padding-right: 4rem;
  float: left;
}

.mega-menu__feature-title {
  margin-bottom: 0.75rem;
  padding: 0;
}

.mega-menu__feature-description {
  display: block;
  line-height: 1.56;
  color: $palest-grey;
  font-weight: normal;
}

.mega-menu__feature-link {
  color: $white;
  border: none;
  text-decoration: none;
  font-size: pxToRem(24);
  padding: 0;
  &:hover,
  &:focus {
    color: $white;
    text-decoration: underline;
  }
}

.mega-menu__navs-wrapper {
  @include sq-flex-box();
  flex-wrap: wrap;
  background: none;
  width: 70%;
  padding-top: 0;
  padding-bottom: 0;

  > .mega-menu__list {
    width: 33%;
    padding-right: 3rem;

    &:nth-child(3n) {
      padding-right: 0;
    }

    &:nth-child(n + 4) {
      margin-top: 2rem;
    }
  }
}

.mega-menu__list {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  float: left;

  .lvl-2 & {
    display: block;
    background: none;
    padding-top: 0;
    padding-bottom: 0;
    .mega-menu__sub-nav-link {
      padding-left: 0;
    }
  }
}

.mega-menu__sub-nav-title {
  margin-top: 0;
  margin-bottom: 0.5rem;
  padding: 0;

  .mega-menu__sub-nav-link,
  .mega-menu__sub-nav-text {
    font-size: pxToRem(18);
    color: $white;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
  }
}

.mega-menu__sub-nav-link,
.mega-menu__sub-nav-text {
  color: $white;
  display: inline-block;
  padding: 0.2rem 0;
  text-decoration: none;
  @include sq-transition(0.1s color ease-in-out);

  &:hover,
  &:focus {
    color: $white;
    text-decoration: underline;
  }
}

.mega-menu__sub-nav-text {
  font-weight: 600;
  &:hover {
    text-decoration: none;
  }
}

.main-menu__btn,
.header-search__mobile-btn {
  display: none;
}

.top-nav__list {
  a {
    font-size: pxToRem(18);
  }
}

.top-nav__list li.highlighted a {
  padding-right: 0;
  width: pxToRem(300);
}
