/* Module: S */
.lhs__menu {
  margin-top: pxToRem(16);
  margin-bottom: pxToRem(34);
  @include font-smoothing;
  border-radius: pxToRem(10);
  overflow: hidden;
}
.lhs__menu-toggle {
  color: $white;
  background-color: $dark-cyan;
  position: relative;
  width: 100%;
  display: block;
  font-size: pxToRem(16);
  font-weight: 500;

  border: 0;
  text-align: left;
  padding: pxToRem(13) pxToRem(20);
  @include sq-transition(0.2s all linear);
  @include font-smoothing();

  &:focus {
    outline: 0;
  }

  &::after {
    content: "";
    position: absolute;
    top: pxToRem(15);
    right: pxToRem(16);
    display: block;
    width: pxToRem(12);
    height: pxToRem(12);
    border-right: 2px solid $white;
    border-bottom: 2px solid $white;
    @include sq-transition(0.2s transform linear, 0.2s border-color linear);
    @include sq-transform(rotate(45deg));
  }

  &[data-expanded="true"] {
    color: $white;
    background-color: $dark-cyan;
    &::after {
      top: pxToRem(21);
      @include sq-transform(rotate(225deg));
    }
  }
}

.lhs__menu-list:not(.menu__level-2, .menu__level-3, .menu__level-4, .menu__level-5) {
  display: none;
  margin-bottom: 2rem;
  border-radius: 0 0 pxToRem(10) pxToRem(10);
  overflow: hidden;
}

.lhs__menu-list {
  @extend %list-reset;
  &.menu__level-2,
  &.menu__level-3,
  &.menu__level-4,
  &.menu__level-5 {
    //border-top: 1px solid $white-five;
  }

  &.menu__level-2 {
    padding: 0 pxToRem(20);
    background: #cdd3d6;
  }

  &.menu__level-3,
  &.menu__level-4 {
    padding: 0 pxToRem(10);
  }
}

.lhs__menu-item {
  border-bottom: 1px solid $white-five;

  &:last-child {
    border-bottom: 0;
    padding-bottom: pxToRem(10);
  }

  &.is-hidden {
    display: none;
  }

  .menu__level-2 & {
    border-color: rgba($dark-cyan, 0.2);
  }

  // We will only have 1 level 3 item in the alternate menu.
  .lhs__menu--alternate .menu__level-2 > & {
    border-bottom: 0;
  }
}

.lhs__menu-link {
  display: block;
  position: relative;
  font-size: pxToRem(18);
  text-decoration: none;
  color: #002664; //$light-navy;
  background-color: #cdd3d6;
  padding: pxToRem(13) pxToRem(20);
  @include sq-transition(
    0.1s color ease-in-out,
    0.1s background-color ease-in-out
  );

  &.active,
  &.current {
    font-weight: bold;
    color: $dark-cyan;
  }

  &.current.has-children {
    padding-right: 2.75rem;

    &::after {
      content: "";
      position: absolute;
      right: 1.15rem;
      top: 0.95rem;
      display: block;
      height: 10px;
      width: 10px;
      border-right: 2px solid $light-navy;
      border-bottom: 2px solid $light-navy;
      @include sq-transform(rotate(45deg));
    }
  }

  &:hover,
  &:focus {
    background-color: #cdd3d6;
    color: $light-navy;
  }

  .menu__level-2 & {
    color: $dark-cyan;
    background-color: #cdd3d6; // $white-two;
    padding: pxToRem(10) 0 pxToRem(10) pxToRem(20);

    &.active,
    &.current {
      font-weight: bold;
    }

    &:hover,
    &:focus {
      background-color: #cdd3d6;
      color: $light-navy;
    }
  }

  .menu__level-3 & {
    font-size: pxToRem(16);
  }

  .menu__level-4 & {
    font-size: pxToRem(15);
  }
}

.rhs__column {
  margin-top: $rhs-column__margin-left;

  .is-listing + & {
    margin-top: 0;
    @include sq-order(-1);
  }

  img {
    width: 100%;
    height: auto;
    display: block;
  }
}

.rhs__panel {
  position: relative;
  margin-bottom: pxToRem(30);
  background-color: $dark-cyan;
  border-radius: pxToRem(10);
  overflow: hidden;

  &.rhs__related {
    background-color: transparent;
    padding: pxToRem(30) 0 0 0;
  }
}
.rhs__image-panel {
  display: block;
  height: 100%;
  .rhs__panel-image {
    height: 100%;
    object-fit: cover;
  }
}
.rhs__panel-content {
  .rhs__panel-heading {
    font-size: pxToRem(25);
    font-weight: 500;
    color: $orange;
    padding: 0.75rem 1rem;
    margin-top: 0;
    margin-bottom: 0;

    .rhs__related & {
      background-color: transparent;
      padding: 0;
      margin-bottom: 1rem;
    }

    &.inside-content {
      font-size: pxToRem(18);
      font-family: $secondary__font-family;
      font-weight: normal;
      background-color: transparent;
      border-bottom: 0;
      padding: 0;
      margin-bottom: 0.5rem;
      color: $white;

      & ~ p {
        font-size: pxToRem(15);
        border-top: pxToRem(1) solid $white;
        padding-top: pxToRem(10);
      }
    }
  }

  a {
    color: $white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.rhs__related {
  .rhs__panel-heading {
    font-size: pxToRem(20);
    color: $orange;
    font-weight: 500;
  }
}

.rhs__panel-links__list {
  @extend %list-reset;
}

.rhs__panel-image {
  width: 100%;
  display: block;
}

.rhs-panel__link {
  position: relative;
  color: $dark-cyan;
  display: block;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @include sq-transition(0.15s color ease-in-out);
  font-size: pxToRem(18);
  font-weight: 400;
  line-height: 2;

  &:hover,
  &:focus {
    color: $dark-cyan;
    text-decoration: underline;
  }

  &:last-child {
    border-bottom: 0;
  }
}

.rhs__panel-content {
  position: relative;
  z-index: 2;
  padding: pxToRem(14) pxToRem(20) pxToRem(20) pxToRem(20);
  font-size: pxToRem(14);
  line-height: 1.64;
  color: $white;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $white;
    font-size: pxToRem(16);
    font-weight: 500;
    line-height: 1.38;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      text-decoration: none;
      color: $white;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  > h2:first-child,
  > h3:first-child,
  > h4:first-child,
  > h5:first-child {
    margin-top: 0;
  }

  p {
    font-weight: normal;
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

%heading {
  position: relative;
  display: block;
  width: 100%;
  border: 0;
  outline: 0;
  padding: 0.65rem 1rem;
  line-height: 1.5;
  text-align: left;
  text-decoration: none;
  @include sq-flex(1 1 0%);
  @include sq-transition(0.15s background-color linear);
}

.rhs-filters {
  &__container {
    border-radius: pxToRem(10);
    background: $white-two;
    overflow: hidden;
    .rhs-filters__toggle {
      margin: 0;
    }
  }
  &__main {
    padding: pxToRem(20) pxToRem(20) 0 pxToRem(20);
  }
}

.accordion,
.tabs,
.mobile-tabs__accordion {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.accordion {
}

.accordion__block {
  background-color: $white-two;
  margin-bottom: pxToRem(10);
  border-radius: pxToRem(10);
  overflow: hidden;

  &:last-child {
    margin-bottom: 0;
  }
}

.accordion__heading {
  color: $dark-cyan;
  font-weight: bold;
  font-size: pxToRem(18);
  line-height: 1.5;
  padding: pxToRem(18) pxToRem(20);
  @extend %heading;
  background-color: $white-two;
  @include font-smoothing;
  @include sq-flex-box();
  @include sq-justify-content(space-between);

  &:hover {
    background-color: $white-two;
  }

  // &::before {
  //     content: '';
  //     position: absolute;
  //     display: inline-block;
  //     width: 3px;
  //     height: 13px;
  //     top: 21px;
  //     right: 25px;
  //     background-color: $dark-cyan;
  // }

  // &::after {
  //     content: '';
  //     position: absolute;
  //     right: 1.25rem;
  //     top: 26px;
  //     display: inline-block;
  //     width: 13px;
  //     height: 3px;
  //     background-color: $dark-cyan;
  // }

  .active > & {
    &::before {
      content: none;
    }
  }
}

.accordion__content {
  display: none;
  overflow: hidden;
  padding: pxToRem(4) pxToRem(20) pxToRem(20);

  p {
    &:first-of-type {
      margin-top: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    &:first-child {
      margin-top: 0 !important;
    }
  }
}

.mobile-tabs__accordion {
  .accordion__block {
    background-color: transparent;

    &.active {
      .accordion__heading {
        &:after {
          top: pxToRem(21);
          @include sq-transform(rotate(225deg));
        }
      }
    }
  }

  .accordion__heading {
    background-color: $dark-cyan;
    color: $white;
    @include font-smoothing;
    font-size: pxToRem(16);
    padding: pxToRem(12) pxToRem(20);

    &:before {
      content: none;
    }

    &:after {
      content: "";
      position: absolute;
      top: pxToRem(15);
      right: pxToRem(16);
      display: block;
      width: pxToRem(12);
      height: pxToRem(12);
      border-right: 2px solid $white;
      border-bottom: 2px solid $white;
      background-color: transparent;
      @include sq-transition(0.2s transform linear, 0.2s border-color linear);
      @include sq-transform(rotate(45deg));
    }

    &:hover {
      background-color: $dark-navy;
    }
  }

  .accordion__content {
    border-bottom: 1px solid $white-two;
    border-left: 1px solid $white-two;
    border-right: 1px solid $white-two;
    padding: pxToRem(20);
    border-radius: 0 0 pxToRem(10) pxToRem(10);
  }
}

.tabs {
  position: relative;
  display: none;
}

/* stylelint-disable */
.tab__buttons {
  @include sq-flex-box;
}
/* stylelint-enable */

.tab__button {
  color: $tabs-tab__color;
  background-color: $tabs-tab__bg;
  text-align: center;
  @extend %heading;
  font-weight: bold;
  margin-right: 4px;
  border: 1px solid transparent;
  border-bottom: 0;

  &:first-child {
    margin-right: 0;
    border-radius: pxToRem(10) 0 0 0;
  }

  &:last-child {
    margin-right: 0;
    border-radius: 0 pxToRem(10) 0 0;
  }

  &:hover,
  &:focus {
    color: $tabs-tab__color--hover;
    background-color: $tabs-tab__bg--hover;
  }

  &.active {
    color: $tabs-tab__color--active;
    background-color: $tabs-tab__bg--active;
    border: 1px solid $accordion-separator__color;
    border-bottom: 0;
  }
}

.tabs__contents {
  position: relative;
  border: 1px solid $accordion-separator__color;
  border-top: 0;
}

.tab__content {
  display: none;
  padding: 1rem;
  background-color: $tabs-content__bg;

  p {
    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &.active {
    display: block;
  }
}

.gallery {
  max-width: $gallery__max-width;
  margin: 0 auto;
  .pagination {
    visibility: hidden;
    height: 0;
    overflow: hidden;
    padding-bottom: 0;
  }
}

.gallery__single {
  position: relative;
  z-index: 1;
  border-radius: pxToRem(10) pxToRem(10) 0 0;
  overflow: hidden;
}

.gallery__nav {
  background-color: #fff;
  margin: 0 pxToRem(30) 3rem;
  padding: pxToRem(12) pxToRem(1);
  position: relative;
  top: pxToRem(-20);
  z-index: 2;
  border-radius: pxToRem(15);

  .slick-slide {
    border-radius: pxToRem(10);
    img {
      border-radius: pxToRem(10);
    }
  }

  .gallery__item {
    cursor: pointer;
    border-left: pxToRem(11) solid transparent;
    border-right: pxToRem(11) solid transparent;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba($black, 0.4);
      opacity: 0;
      border-radius: pxToRem(10);
      @include sq-transition(opacity 0.3s);
    }

    &:hover,
    &:focus {
      outline: none;

      &:after {
        opacity: 1;
      }
    }
  }

  .slick-list {
    position: relative;
  }

  .slick-next,
  .slick-prev {
    position: absolute;
    top: pxToRem(50);
    right: -1.75rem;
    padding: 0;
    margin: pxToRem(-13) 0 0 0;
    background-color: transparent;
    color: transparent;
    text-indent: 200%;
    overflow: hidden;
    border: 0;
    height: pxToRem(28);
    width: pxToRem(28);

    &:hover,
    &:focus {
      &:before {
        background: $dark-navy;
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: pxToRem(8);
      left: pxToRem(5);
      height: 12px;
      width: 12px;
      border-right: 2px solid $white;
      border-bottom: 2px solid $white;
      @include sq-transform(rotate(-45deg));
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 28px;
      width: 28px;
      @include sq-transform(rotate(0deg));
      background: $dark-cyan;
      border-radius: 20px;
    }
  }

  .slick-prev {
    left: -1.75rem;
    right: auto;
    &::after {
      left: pxToRem(9);
      right: pxToRem(6);
      border-right: 0;
      border-left: 2px solid $white;
      border-bottom: 2px solid $white;
      @include sq-transform(rotate(45deg));
    }
  }
}

.gallery__image {
  display: block;
}

.gallery__description {
  color: $gallery-description__color;
  background-color: $gallery-description__bg;
  padding: 0.5rem 0.75rem;
  margin-top: 0.5rem;
}

.form__group,
.sq-form-question {
  margin-bottom: pxToRem(20);

  label {
    color: #393939;
    font-size: pxToRem(18);
  }

  .form__group:last-child,
  .form__check:last-child {
    margin-bottom: 0;
  }

  legend {
    font-size: pxToRem(20);
    font-weight: 500;
  }
}

.rhs-filters__container {
  .form__group,
  .sq-form-question {
    label:not(.btn-filter) {
      width: calc(100% - 1.5rem);
    }
  }
}

.sq-form-question-answer {
  > ul {
    > li {
      padding-left: 0 !important;
      margin-bottom: 0 !important;
      &::before {
        content: none !important;
      }
    }
  }
}

.form__group-inline {
  position: relative;
  @include sq-flex-box;

  > .datepicker__wrap {
    display: block;
    width: 100%;
  }

  > input[type="text"],
  > .datepicker__wrap {
    @include sq-flex-shrink(1);
  }
}

.form-fieldset__content {
  display: none;
  margin-top: 0.75rem;

  li {
    @include sq-flex-box();
    align-items: flex-start;
    input[type="checkbox"],
    input[type="radio"] {
      margin-right: pxToRem(10);
      margin-top: 7px;
      vertical-align: top;
    }

    &:last-child {
      label {
        margin-bottom: 0;
      }
    }
  }
}

.sq-form-question-option-list li,
.sq-form-question-tickbox-list li,
.form__check {
  position: relative;
  display: block;
  margin-bottom: 0.25rem;
}

.sq-form-question-option-list label,
.sq-form-question-tickbox-list label,
.form-check__label {
  color: $form-check__color;
  padding-left: 1.5rem;
  line-height: 1.5rem;
  margin-bottom: 0;
  cursor: pointer;

  .disabled > & {
    color: $form-check__color--disabled;
    cursor: not-allowed;
  }
}

.sq-form-question-option-list input[type="radio"],
.sq-form-question-tickbox-list input[type="checkbox"] {
  position: absolute;
  margin-top: 0.25rem;
  margin-left: 0;
}

.form-check__input {
  position: absolute;
  margin-top: 0.25rem;
  margin-left: -1.5rem;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: pxToRem(14);
  line-height: 1.64;
}

.form__control,
.sq-form-field:not([type="radio"]):not([type="checkbox"]) {
  display: block;
  width: 100%;
  padding: pxToRem(8) pxToRem(14);
  color: $greyish-brown;
  font-size: pxToRem(14);
  line-height: 1.64;
  border: 1px solid $white-five;
  border-radius: pxToRem(15);
  resize: none;
  background-color: transparent;
  @include sq-transition(
    0.2s background-color ease-in-out,
    0.2s border-color ease-in-out,
    0.2s color ease-in-out
  );

  &:focus {
    border-color: $greyish-brown;
    outline: 0;
  }

  .has-success & {
    border-color: $turquoise-blue;
  }

  .has-warning & {
    border-color: $orange;
  }

  .has-error & {
    border-color: $scarlet;
    background-color: $very-light-pink;
  }

  .form__group-inline > & {
    margin-right: 0.5rem;

    &:last-child:not(:first-child) {
      margin-right: 0;
    }
  }
}

textarea {
  height: pxToRem(120);
}

.form__check {
  &:last-child {
    label {
      padding-bottom: 0;
    }
  }

  input[type="radio"] {
    display: none;

    &:checked {
      & + label {
        &:after {
          content: "";
          width: pxToRem(6);
          height: pxToRem(6);
          border-radius: 100%;
          position: absolute;
          left: pxToRem(5);
          top: pxToRem(8);
          background-color: $black-two;
        }
      }
    }

    & + label {
      position: relative;
      padding: 0 0 pxToRem(10) pxToRem(32);

      &:before {
        content: "";
        width: pxToRem(16);
        height: pxToRem(16);
        border-radius: 100%;
        position: absolute;
        left: 0;
        top: pxToRem(3);
        border: 1px solid $white-five;
      }
    }
  }

  input[type="checkbox"] {
    display: none;

    &:checked {
      & + label {
        &:after {
          content: "";
          width: pxToRem(16);
          height: pxToRem(16);
          position: absolute;
          left: 0;
          top: pxToRem(3);
          background: transparent url(../mysource_files/tick.svg) center center
            no-repeat;
          background-size: pxToRem(10);
        }
      }
    }

    & + label {
      position: relative;
      padding: 0 0 pxToRem(10) pxToRem(32);

      &:before {
        content: "";
        width: pxToRem(16);
        height: pxToRem(16);
        position: absolute;
        left: 0;
        top: pxToRem(3);
        border: 1px solid $white-five;
      }
    }
  }
}

select.form__control:not([size]):not([multiple]),
select.sq-form-field:not([size]):not([multiple]) {
  padding-right: 2rem;
  height: 40px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("../mysource_files/select.svg");
  background-repeat: no-repeat;
  background-position: center right 12px;
  background-size: 12px;
}

/* stylelint-enable */

.form-control__feedback {
  margin-top: 0.3rem;
  font-size: pxToRem(14);
  line-height: 1.64;

  .has-success & {
    color: $deep-turquoise;
  }

  .has-warning & {
    color: $orange;
  }

  .has-error & {
    color: $scarlet;
  }
}

.sq-form-question-error {
  .form__control {
    border-color: $scarlet;
  }
  .sq-form-error {
    color: $scarlet;
    margin-top: 0.5rem;
  }
}

.form__text {
  display: block;
  margin-top: 0.25rem;
  font-size: $form-text__font-size;
  color: $form-text__color;
}

.form__list {
  @extend %list-reset;
}

.form-list__item {
  @extend %clearfix;
  margin-bottom: 0.6rem;

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
  }
}

.form-list__link {
  @include sq-transition(0.15s color ease-in-out);
  font-size: pxToRem(18);
  font-weight: 500;
  color: $dark-cyan;
  text-decoration: none;

  &:hover,
  &:focus,
  &.active {
    color: $turquoise-blue;
    & + .form-list__count {
      color: $turquoise-blue;
    }
  }
}

.form-list__count {
  float: right;
  font-size: pxToRem(15);
  font-weight: 500;
  line-height: 2;
  color: $dark-cyan;
}

.form__separator {
  margin-top: pxToRem(30);
  margin-bottom: pxToRem(30);
  border-top-color: $deep-turquoise;
  border-bottom: 0;
}

.toc__box {
  display: none;
}

.toc__list {
  @extend %list-reset;
  display: none;
}

.toc__toggler {
  font-size: 1rem;
  color: #58595b;
  background-color: transparent;
  font-weight: 600;
  border: 0;
  padding: 0;
  margin: 0;
  float: right;
  display: none;
}

h3.toc__heading {
  color: $orange;
  font-size: pxToRem(25);
  font-weight: bold;
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: 1rem;
}

.toc__link {
  position: relative;
  display: block;
  font-size: pxToRem(18);
  font-weight: 500;
  line-height: 1.93;
  color: $dark-cyan;
  text-decoration: none;

  @include sq-transition(0.15s color ease-in-out);

  &:hover,
  &:focus {
    color: $dark-cyan;
    text-decoration: underline;
  }
}

.toc__item {
  &:last-child {
    margin-bottom: 0;
  }

  // Yes, IE is retarded and doesn't respect :not()
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    padding-left: 0 !important;
    margin-left: 0 !important;

    &:before {
      content: none !important;
    }
  }
}

/* stylelint-disable */
.gMap {
  width: 100%;
  height: 400px;
}
/* stylelint-enable */
.btt__wrapper {
  width: 100%;
  position: absolute;
  bottom: 100%;
  left: 0;
  padding: 0 1.5rem;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    bottom: calc(100% - 1px);
  }
}
.btt {
  max-width: 80rem;
  margin: 0 auto;
}

.btt a {
  float: right;
  display: block;
  height: 36px;
  width: 80px;
  position: relative;
  background-color: $dark-cyan;
  transition: all 0.3s ease;
  color: #fff;
  &::before {
    content: "";
    position: absolute;
    border-width: 3px 0 0 3px;
    border-style: solid;
    border-color: #fff;
    width: 12px;
    height: 12px;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%) rotate(45deg);
    transition: all 0.3s ease;
  }
  &:hover,
  &:focus {
    background: $dark-navy;
  }
}

.figure__image {
  position: relative;

  img {
    display: block;
  }

  figcaption {
    color: $greyish-brown;
  }
}

.popup__video {
  text-decoration: none;
}

.addtocalendar {
  position: relative;
  display: inline-block;
  background: transparent;

  var {
    display: none;
  }

  .atcb-list {
    @extend %list-reset;
    position: absolute;
    left: 0;
    display: none;
    background-color: #fff;
    margin-top: 0.25rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.25rem;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);

    &:hover {
      display: block;
    }
  }

  .atcb-link:focus,
  .atcb-link:active {
    + .atcb-list {
      display: block;
    }
  }

  .atcb-item-link {
    font-size: pxToRem(15);
    padding: 0.35rem 0.75rem;
    display: block;
    text-decoration: none;
    white-space: nowrap;

    &:hover,
    &:focus {
      background-color: darken(#fff, 6%);
    }
  }
}

.at-share-tbx-element .at-icon-wrapper {
  display: none !important;
}
.video-container {
  height: 0;
  margin-bottom: 1rem;
  overflow: hidden;
  padding-bottom: 56.25%; // Video area
  padding-top: pxToRem(30); // 30px controls container
  position: relative;
  border-radius: pxToRem(10);
}

.video-container embed,
.video-container iframe,
.video-container object {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.video-poster a {
  img {
    vertical-align: bottom;
    width: 100%;
    // @include sq-transform(scale(1));
    // @include sq-transition(.3s transform linear);
    // -webkit-backface-visibility: hidden;
    // backface-visibility: hidden;
  }
  .image__wrapper {
    position: relative;
    overflow: hidden;
    border-radius: pxToRem(10);
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      background-color: #262626;
      opacity: 0.6;
      position: absolute;
    }
    &::after {
      content: "";
      top: calc(50% - 25px);
      right: calc(50% - 25px);
      position: absolute;
      background-image: url("data:image/svg+xml;utf8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgMzU3IDM1NyI+PHBhdGggZmlsbD0iI0ZGRiIgZD0iTTM4LjI1IDB2MzU3bDI4MC41LTE3OC41eiIvPjwvc3ZnPg==");
      background-repeat: no-repeat;
      background-size: 50px;
      background-position: center center;
      height: 50px;
      width: 50px;
    }
  }
  &:hover img {
    // @include sq-transform(scale3d(1.2,1.2,1));
  }
}
.map__description {
  position: relative;
  padding: pxToRem(31);
  background-color: $home-box__bg;
  @include drop-shadow();
  color: #2e2e2e;
  h3.map__description-heading {
    color: $home-box-heading__color;
    margin-top: 0;
    margin-bottom: pxToRem(21);
    font-size: pxToRem(22);
  }
  p {
    margin: 0;
  }
}

.breadcrumbs__divider {
  display: none;
}

.breadcrumbs__link,
.breadcrumbs__current {
  font-size: pxToRem(14);
  font-weight: 500;
  color: $dark-cyan;
  text-decoration: none;
}

.breadcrumbs__link {
  position: relative;
  padding-right: pxToRem(5);

  &:hover {
    text-decoration: underline;
    color: $deep-turquoise;
  }

  &:after {
    content: "";
    border: solid $dark-cyan;
    border-width: 0 pxToRem(1) pxToRem(1) 0;
    display: inline-block;
    padding: pxToRem(3);
    @include sq-transform(rotate(-45deg));
    position: relative;
    top: -1px;
    margin-left: pxToRem(5);
  }
}

.responsive__table,
table {
  th,
  td {
    font-size: pxToRem(16);
  }
}

.has_search {
  .breadcrumbs {
    .breadcrumbs__link,
    .breadcrumbs__current {
      color: $dark-cyan;

      &:after {
        border-color: $dark-cyan;
      }
    }

    .breadcrumbs__link {
      &:hover,
      &:focus {
        color: $light-navy;
      }
    }
  }
}
