/* Module: S */
.gradient__wrapper {
    padding-top: 5rem;
    padding-bottom: pxToRem(135);
}

.feature-box__container {
    width: calc(100% / 3 - 20px);

    &.landing-box {
        width: calc(100% / 2 - 20px);
        &:nth-child(3) {
            margin-top: 4rem;
        }
    }

    &.large-box {
        padding-left: 2rem;
        padding-right: 4rem;
        &:nth-child(2) {
          padding-right: 2rem;
          padding-left: 4rem;
        }
    }

    &:nth-child(3) {
        margin-top: 0;
    }

    &:nth-child(n+4) {
        margin-top: 4rem;
    }

    &:not(.large-box) {
        .feature-box__image {
            // @include sq-transform(scale(1));
            // @include sq-transition(.3s transform linear);
            // -webkit-backface-visibility: hidden;
            // backface-visibility: hidden;
        }
        .feature-box__link {
            &:hover,
            &:focus {
                .feature-box__image {
                    // @include sq-transform(scale3d(1.1,1.1,1));
                }
            }
        }
    }
}

.feature-box__link {
    display: block;

    &:hover,
    &:focus {
        .image-box > & {
            .feature-box__content {
                @include sq-transform(translateY(-63px));
            }

            &::before {
                visibility: visible;
                opacity: 1;
            }
        }
    }
}

.feature-box__heading {
    padding: 1.25rem 3em 1.25rem 1.5rem;
    margin-top: -1px;

    &:after {
        top: 1.6rem;
    }

    .large-box & {
        font-size: pxToRem(40);
        padding-bottom: 1.5rem;
    }
}

.feature-box__content {
    font-size: pxToRem(14);
    .large-box & {
        padding-top: 2.25rem;
    }

    .image-box & {
        color: $rhs-panel-content__color;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        @include sq-transition(.3s transform ease-in-out);
        @include sq-transform(translateY(100%));
    }
}
.small-box .feature-box__heading {
    height: pxToRem(63);
}
