/* Module: S */
.gallery {
    .pagination {
        visibility: visible;
        height: auto;
    }
}

.lhs__menu {
    position: relative;
}

.accordion {
    position: relative;
}

.tabs {
    display: inherit;
    margin-bottom: 3rem;
}

.tab__button {
    padding: pxToRem(17) pxToRem(20);
    @include font-smoothing;
}

.tab__content {
    padding: pxToRem(26) pxToRem(20);
}

.mobile-tabs__accordion {
    display: none;
}

.toc__box {
    position: relative;
    z-index:  1;
    width: pxToRem(200);
    display: inline-block;
    float: right;
    margin-left: 2rem;
    margin-bottom: 2rem;
}

.toc__list {
    display: block;
}

.toc__link {
    width: 100%;
    max-width: $toc-box__max-width;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rhs__column {
    margin-top: 3.5rem;
    margin-bottom: .75rem;
    @include sq-flex-box;
    @include sq-flex-wrap(wrap);
    @include sq-justify-content(space-between);
    // @include sq-flex-basis(100%);

    .is-listing + & {
        display: block;
    }
}

.rhs-filters {
    &__container {
        background: none;
    }
}

.rhs__panel {
    width: calc(50% - .75rem);
}

.rhs__related {
    .rhs__panel-heading {
        font-size: pxToRem(25);
    }
}

// Safari have some problems with proper flex wrapping
@media screen and (max-width: 63.9375em) {
    /* stylelint-disable */
    _::-webkit-full-page-media, _:future, :root .rhs__column {
        @include sq-flex-basis(auto);
    }

    _::-webkit-full-page-media, _:future, :root .main__content {
        @include sq-flex-basis(auto);
    }

    _::-webkit-full-page-media, _:future, :root .is-listing + .rhs__column {
        @include sq-flex-basis(auto);
    }
    /* stylelint-enable */
}
