/* Module: S */
.featured-people {
    padding: pxToRem(24) pxToRem(32) 0 pxToRem(32);
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: pxToRem(-170);
        left: pxToRem(-315);
        width: pxToRem(353);
        height: pxToRem(282);
        background-image: url(../mysource_files/HETI_logo_white.svg);
        background-repeat: no-repeat;
        background-position: center;

    }

    &__item {
        width: 25%;

	    &.active {

	    	> a,
            .featured-people__avatar {
	    		width: 25%;
	    	}

            .featured-people__info-wrap {
                top: pxToRem(280);
            }
	    }

        .featured-people__info-wrap {
            .featured-people__info {
                .featured-people__position {
                    .fas {
                        display: block;
                        margin-top: pxToRem(17);
                        position:absolute;
                        left: 45%;
                    }
                }
            }
        }
    }

    &__content {
        position: absolute;
        top: 0;
        left: 25%;
        width: 75%;
        bottom: 5.5rem;
		z-index: 9;
        padding: pxToRem(10) pxToRem(60) pxToRem(26) pxToRem(60);

        &:after {
        	display: none;
        }

        h3 {
            padding: pxToRem(30) 0 0 0;
        }
    }

    &__info {
        bottom: pxToRem(-42);
    }

    &__link {
        padding: pxToRem(32) pxToRem(20);

        &-bg {
            &:before {
                left: auto;
                top:  pxToRem(-60);
                right:pxToRem(-240);
            }

            &:after {
                width:  pxToRem(200);
                height: pxToRem(100);
                border-top-left-radius: pxToRem(200);
                border-top-right-radius: pxToRem(200);
                left: auto;
                top:  pxToRem(-102);
                right:pxToRem(-100);
            }
        }
    }
}


.section--pathways {
    padding-top: pxToRem(30);

    &:before {
        display: none;
    }

    &:after {
        bottom: pxToRem(130);
        right: pxToRem(-80);

    }
}
