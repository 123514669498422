.wrapper {
  padding-left: pxToRem(20);
  padding-right: pxToRem(20);
}

.main__content {
  &.is-listing {
    @include sq-order(inherit);
    margin-top: 0;
  }
  &.is-listing.listing-3-col {
    min-width: 37.1%;
  }
}

.main__content {
  font-size: pxToRem(18);
  h1 {
    font-size: pxToRem(40);
  }

  h2 {
    &.section-title {
      padding: pxToRem(24) pxToRem(20);
    }
  }

  blockquote {
    padding-top: 0;
    margin: pxToRem(40) 0;
    max-width: pxToRem(450);
  }
}

.main__form {
  width: 100%;
}

/*
--------------------
Modules
--------------------
*/
