/* Module: S */
.related-content {
	margin-top: pxToRem(24);

    &__wrapper {
	    padding: 0 pxToRem(12);
	}

    &__item {
        width: calc(25% - 0.5px);
    }
}
