/* Module: S */
.search-result__link {
    @include sq-flex-box;
}

.search-result__image-wrapper {
    position: absolute;
    height: 100%;
    width: pxToRem(150);

    & + .search-result__content {
    	padding-left: pxToRem(176);
    }
}

.search-result__type {
    // position: absolute;
    // top: pxToRem(28);
    // right: pxToRem(24);
    // width: pxToRem(120);
    // text-align: right;
    // padding-bottom: 0;

    // & + div,
    // & + h2 {
    //     padding-right: pxToRem(140);
    // }
}

.search-result__desc {
    margin-top: pxToRem(20);
}
