/* Module: S */
.panels__container {
    @include sq-flex-box;
    @include sq-flex-wrap(wrap);
    @include sq-justify-content(space-between);
}

.landing-panel {
    width: calc(#{$landing-panel__width} - #{$landing-panel__space-between / 2});

    &:nth-child(2) {
        margin-top: 0;
    }
}
.landing-image {
  height: pxToRem(345);
}
