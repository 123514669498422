/* Module: S */
.quicklinks__wrapper {
    bottom: pxToRem(150);
}

.quicklinks__heading {
    // display: inline-block;
    padding: pxToRem(13) pxToRem(20);
    // position: absolute;
    // left: pxToRem(-114);
    // top: pxToRem(-13);
    // z-index: 2;
}

.quicklinks__block {
    .quicklinks__heading {
        position: relative;
    }
}