/* Module: S */
.news {
	background-color: $white;
	position: relative;

    &__wrapper {
		padding: 0 pxToRem(12);
	    @include sq-flex-box;
	    @include sq-justify-content(center);
    }

    &__column {
        margin: 0;
		padding: pxToRem(80) 0 pxToRem(60) 0;
		position: relative;

        &:first-child {
        	width: 75%;
            @include dot-bg ($white, $white-three, before);
        }

        &:last-child {
        	width: 25%;
			padding: pxToRem(80) 0 pxToRem(60) pxToRem(15);
            @include dot-bg ($white-two, $white-five, before);
        }
    }

    &__text {
    	display: block;
    }

    &__blocks {
    	.news {
		    &__item {
		        width: 33.333%;

		        &:nth-child(2n+1){
				    clear: none;
				}
		    }
    	}
    }

    &__list {
    	padding: pxToRem(4) 0;
    }

}