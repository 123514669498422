/* Module: S */
/* stylelint-disable */
.main__footer {
    color: $white;
    background-color: $dark-cyan;
    padding: pxToRem(16) pxToRem(20) 0 pxToRem(20);
    margin-top: 4rem;
    @include font-smoothing;
    padding-top: pxToRem(30) !important;
    .btt a {
        border-radius: pxToRem(100) pxToRem(100) 0 0;
        height: pxToRem(40);
    }

}
/* stylelint-enable */

.footer-links__logo {
    padding: pxToRem(28) 0;
}

.footer-links__list,
.footer-social__list,
.footer-contact__list {
    @extend %list-reset;
}

.footer-links__list,
.footer-contact__heading {
    margin: 0;
    padding: pxToRem(16) 0;
}

/* stylelint-disable */
.footer-links__wrapper {
    width: 100%;
    max-width: 80rem;
    position: relative;

    &:before {
        z-index: 0;
        content: '';
        position: absolute;
        top:  pxToRem(-60);
        left: pxToRem(35);
        width: 80px;
        height: 40px;
        border-top-left-radius: pxToRem(80);
        border-top-right-radius: pxToRem(80);
        background-color: $dark-cyan;
    }

    &:after {
        z-index: 0;
        content: '';
        position: absolute;
        top:  pxToRem(-31);
        left: pxToRem(-4);
        width: 40px;
        height: 20px;
        border-bottom-left-radius: pxToRem(40);
        border-bottom-right-radius: pxToRem(40);
        background-color: $white;
    }
}
/* stylelint-enable */

.footer-links__column {
    position: relative;
    padding-top: pxToRem(13);

    &:first-child {
        padding-top: pxToRem(10);
        flex: 0 0 250px; /* do not grow, do not shrink, start at 250px */

        &:before {
            display: none;
        }
    }

    &:before {
        content: '';
        display: inline-block;
        width: pxToRem(18);
        height: pxToRem(3);
        background-color: transparent;
        position: absolute;
        top: pxToRem(10);
        left: 0;
    }
}

.footer-link,
.footer-contact__heading {
    font-size: pxToRem(14);
    font-weight: normal;
    line-height: 1.83;
    color: $white;
    text-decoration: none;
    display: block;
}

.footer-links__column {
    padding-top: pxToRem(3);
    .footer-links__list {
        padding: 0;
    }
}

.footer-link{
    margin-bottom: pxToRem(18);
    &:hover,
    &:focus {
        color: $duck-egg-blue;
    }
}

/* stylelint-disable */
.footer-social__wrapper {
    @include sq-flex-box;
    @include sq-justify-content(center);
    background-color: $dark-navy;
    margin-top: pxToRem(40);
    margin-left: pxToRem(-20);
    margin-right:  pxToRem(-20);
    padding-top: pxToRem(10);
    padding-bottom: pxToRem(10);
}
/* stylelint-enable */

.footer-icon {
    fill: $white;
    width: pxToRem(26);
    height: pxToRem(26);
    display: block;
    margin: pxToRem(5) auto;
}

.footer-social__link {
    display: inline-block;
    width: pxToRem(19);
    height: pxToRem(19);
    margin-right: pxToRem(11);
    background-color: transparent;
    border-radius: pxToRem(5);
    @include sq-transition(.1s background-color ease-in-out);

    .sr-only {
        color: #fcffff;
    }

    &:last-child {
        margin-right: 0;
    }

    &.facebook,
    &.twitter,
    &.linkedin,
    &.vimeo,
    &.instagram,
    &.youtube {
        fill: #ffffff;
    }


    &.facebook {
        &:hover,
        &:focus {
            fill: #3b5998;
        }
    }

    &.twitter {
        &:hover,
        &:focus {
            fill: #0084b4;
        }
    }

    &.linkedin {
        &:hover,
        &:focus {
            fill: #0077b5;
        }
    }

    &.vimeo {
        padding-top: pxToRem(3);
        &:hover,
        &:focus {
            fill: #182322;
        }
    }

    &.youtube {
        &:hover,
        &:focus {
            fill: #bb0000;
        }
    }

    &.instagram {
        &:hover,
        &:focus {
            fill: #bc2a8d;
        }
    }
}
