/* Module: S */
/* stylelint-disable */
.feature-panels__wrapper {
    @include sq-flex-box;
    @include sq-justify-content(space-between);
    @include sq-flex-wrap(wrap);
    max-width: $page__max-width;
    margin: 0 auto;
    padding: 2rem 1rem;

    .gradient__wrapper & {
        padding: 0;
    }

    + .feature-panels__wrapper {
        margin-bottom: 0;
    }
}
/* stylelint-enable */

.feature-box__container {
    position: relative;
    width: 100%;
    background-color: $home-box__bg;
    margin-top: 1.5rem;

    &.large-box {
        margin-top: 0;
        background-color: transparent;
    }

    &:not(.large-box) {
        @include sq-flex-box;
        @include sq-flex-direction(column);
    }

    &:first-child {
        margin-top: 0;
    }
}

.feature-box__link {
    position: relative;
    overflow: hidden;
    text-decoration: none;
    @include sq-flex-box;
    @include sq-flex-direction(column);
    @include sq-flex(1 0 auto);

    .large-box & {
        padding: 3rem 2rem;
    }

    .image-box > & {
        &::before {
            content: '';
            background-color: $home-box-image-hover-overlay__bg;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            visibility: hidden;
            opacity: 0;
            z-index: 2;
            @include sq-transition(.3s opacity ease-in-out, .3s visibility ease-in-out);
        }
    }

    &:hover,
    &:focus {
        .feature-box__heading {
            color: $home-box-heading__color--hover;
            background-color: $home-box-heading__bg--hover;

            .large-box & {
                color: $home-box-heading__color;
                background-color: transparent;
            }

            &::after {
                border-bottom-color: $home-box-heading__color--hover;
                border-right-color: $home-box-heading__color--hover;
                right: 1.35rem;
            }
        }
    }
}

.feature-box__heading {
    position: relative;
    color: $home-box-heading__color;
    font-weight: 600;
    background-color: $home-box-heading__bg;
    line-height: 1.25;
    margin-top: 0;
    margin-bottom: 0;
    padding: 1rem 2.75em 1rem 1.25rem;
    z-index: 3;
    @include sq-transition(.3s background-color ease-in-out);
    -webkit-font-smoothing: antialiased;

    &::after {
        content: '';
        position: absolute;
        top: 1.3rem;
        right: 1.75rem;
        display: inline-block;
        width: 10px;
        height: 10px;
        border-right: 2px solid $home-box-heading__color;
        border-bottom: 2px solid $home-box-heading__color;
        @include sq-transition(.2s right linear);
        @include sq-transform(rotate(-45deg));
    }

    .large-box & {
        text-align: center;
        color: $home-box-text__color;
        font-size: 180%;
        line-height: 1.45;
        padding: 0;
        background-color: transparent;

        span {
            font-weight: bold;
        }

        &::after {
            content: none;
        }
    }
}

.feature-box__content {
    font-size: pxToRem(14);
    color: $rhs-panel-content__color;
    background-color: $home-box__bg;
    line-height: 1.75;
    padding: 1rem;

    @include unordered-list($color__tertiary, true);

    .large-box & {
        padding: 1.25rem 0 0;
        background-color: transparent;
        display: none;
    }

    .event-date {
        font-size: 85%;
        font-weight: bold;
        color: $color__tertiary;
        margin-right: .25rem;
    }

    a {
        color: $home-box-links__color;
        text-decoration: none;
        @include sq-transition(.05s all ease-in-out);

        &:hover,
        &:focus {
            color: $home-box-links__color--hover;
            text-decoration: underline;
        }
    }
}

.feature-box__image {
    width: 100%;
    height: auto;
    display: block;
    @include sq-order(-1);

    .large-box & {
        display: none;
        @include sq-order(inherit);
    }
}

@media screen and (max-width: 767px) {
    .gradient__wrapper {
        .large-box {
            &:nth-child(odd) {
                .feature-box__link {
                    background-color: #fff;

                    .feature-box__heading {
                        color: #2b2b2b;
                    }
                }
            }

            &:nth-child(even) {
                .feature-box__link {
                    color: #fff;
                    background-color: #2b2b2b;

                    .feature-box__heading {
                        color: #fff;
                    }
                }
            }
        }
    }
}
.small-box .feature-box__heading {
    padding: 0;
    position: relative;
    height: pxToRem(55);
    font-size: 1.17em;
    span {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        display: inline-block;
        padding: 0 3em 0 1.5em;
    }
}
