/* Module: S */
.event__info-list {
    display: inline-block;

    strong {
        display: inline-block;
    }

    li {
        margin-bottom: 0;
    }
}

/* stylelint-disable */
_::-webkit-full-page-media, _:future, :root .event__info-list {
    display: block;
    max-width: 40rem;
}
/* stylelint-enable */
