/**
 * version: {{version}}
 * file:    {{file}}
 * author:  Squiz Australia
 * updated: {{date}}
 *
 * Table of Contents
 *
 * - Reset
 * - Utilities
 * - General
 * - Content
 * - Modules
    {{toc}}
 */

// These imports have no CSS output, but provide variables, mixins and helpers to aid in construction of
// a design cutup.
@import "boilerplate.scss";

/*
--------------------
Reset (normalize.css)
--------------------
*/

@import "normalize.scss";

// This import disabled by default due to rendering issues, particularly with IE where some text doesn't render at all
//@import 'normalize-opentype.scss';

// Box size all the things
// https://squizmap.squiz.net/imp-bp/6400
// http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
// == Start box-sizing reset ==
*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

html {
  @include sq-border-box;
}
// == End box-sizing reset ==

/*
--------------------
Utilities
--------------------
*/

@import "utilities.scss";

/*
--------------------
General
--------------------
*/

// Taken from HTML 5 BP
::-moz-selection {
  background: $content-selection__highlight;
  text-shadow: none;
}

::selection {
  background: $content-selection__highlight;
  text-shadow: none;
}

html,
body {
  height: 100%;
}
body {
  font-size: $base__font-scale + em;
  font-family: $base__font-family-publicsans;
  color:#22272b; //$base__font-colour;
  line-height: $base__line-height;
  background-color: $base__bg;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  overflow-x: hidden;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 100%;
    overflow-x: hidden;
  }
}

img {
  max-width: 100%;
}

// Reset fonts for relevant elements (taken from bootstrap)
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

// IE10 Metro responsive
// Required for Windows 8 Metro split-screen snapping with IE10
// Source: http://timkadlec.com/2012/10/ie10-snap-mode-and-responsive-design/
@-ms-viewport {
  width: device-width;
}

// Print style that shouldn't belong to print.scss to avoid the css beautifier from killing
// the @ syntax inside media queries
@page {
  margin: 0.5cm;
}

a {
  color: $dark-cyan;

  &:hover,
  &:focus {
    color: $deep-turquoise;
    text-decoration: none;
  }
}

a[rel="external"] {
  /*&::after {
        content: '';
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTAwIDEwMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBvbHlnb24gZmlsbD0iIzAwNzZhZCIgcG9pbnRzPSI5MS44NzUsNjIgODUsNjIgODUsODUgMTUsODUgMTUsMTUgMzgsMTUgMzgsOC4xMjUgMzgsMCAwLDAgMCwxMDAgMTAwLDEwMCAxMDAsNjIgIj48L3BvbHlnb24+PHBvbHlnb24gZmlsbD0iIzAwNzZhZCIgcG9pbnRzPSI1NCwwIDU0LDAuNjI3IDcxLjc4NywxOC40NzcgNTIuNzAxLDM4IDM4LDM4IDM4LDYyIDYyLDYyIDYyLDQ5Ljc5OSA4Mi43NzMsMjkuNDYyIDk5LjM3NCw0NiAxMDAsNDYgMTAwLDAgIj48L3BvbHlnb24+PC9zdmc+');
        width: 11px;
        height: 11px;
        display: inline-block;
        margin-left: 4px;
    }*/
}

h2 {
  &.section-title {
    font-size: pxToRem(40);
    font-weight: normal;
    font-family: $secondary__font-family;
    //text-transform: uppercase;
    text-align: center;
    max-width: $page__max-width;
    margin: 0 auto;
    padding: pxToRem(14) pxToRem(20);
  }
}

.icon-chevron {
  display: inline-block;
  width: pxToRem(15);
  height: pxToRem(15);
  border-radius: 50%;
  margin-left: pxToRem(10);
  position: relative;
  top: pxToRem(5);
  background: $black-three;
  font-size: pxToRem(18);
  line-height: 1.4;

  &:after {
    content: "";
    display: inline-block;
    width: pxToRem(6);
    height: pxToRem(6);
    border-top: pxToRem(1) solid $white;
    border-right: pxToRem(1) solid $white;
    @include sq-transform(rotate(45deg) translateX(-2px) translateY(-7px));
  }
}

@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  .icon-chevron {
    &:after {
      @include sq-transform(rotate(45deg) translateX(-4px) translateY(-9px));
    }
  }
}

@-moz-document url-prefix() {
  /* Put your Firefox specific code here. */
  .icon-chevron {
    &:after {
      @include sq-transform(rotate(45deg) translateX(-4px) translateY(-9px));
    }
  }
}

@supports (-ms-ime-align: auto) {
  /* Put your WebKit specific code here. */
  .icon-chevron {
    &:after {
      @include sq-transform(rotate(45deg) translateX(-4px) translateY(-9px));
    }
  }
}

@supports (-webkit-appearance: none) {
  .icon-chevron {
    &:after {
      @include sq-transform(rotate(45deg) translateX(-2px) translateY(-7px));
    }
  }
}

.icon-expand {
  display: inline-block;
  width: pxToRem(20);
  height: pxToRem(20);
  border-radius: 50%;
  margin-left: pxToRem(10);
  position: relative;
  top: pxToRem(3);
  background: $dark-cyan;
  font-size: pxToRem(18);
  line-height: 1.4;

  .active & {
    background: $white;

    &:after {
      background-color: $dark-cyan;
    }

    &:before {
      display: none;
    }
  }

  &::before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 1px;
    height: 10px;
    top: 6px;
    right: 9px;
    background-color: $white;
  }

  &::after {
    content: "";
    position: absolute;
    right: 5px;
    top: 10px;
    display: inline-block;
    width: 10px;
    height: 1px;
    background-color: $white;
  }
}

.section {
  background: black;
  &--programs {
    background: #8cdbe5;
  }
  &--news {
    background: $white;
  }
  &--pathways {
    background: $dark-cyan;
    padding-top: pxToRem(30);
    > .section-title {
      color: $white;
    }
  }
}

/*
--------------------
Content
--------------------
*/

/* Buttons */
.btn,
.atcb-link {
  cursor: pointer;
  display: inline-block;
  font-size: pxToRem(16);
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 2px solid transparent;
  padding: pxToRem(5) pxToRem(20);
  border-radius: pxToRem(30);
  @include sq-transition(0.2s all ease-in-out);
}

.btn-primary {
  color: $white;
  background-color: $dark-cyan;
  border-color: $dark-cyan;
  @include font-smoothing;

  &:hover,
  &:focus {
    color: $dark-cyan;
    background-color: $white;
    border-color: $dark-cyan;
  }
  &.btn-inverted {
    color: $dark-cyan;
    background-color: $white;
    border-color: $dark-cyan;

    &:hover,
    &:focus {
      color: $white;
      background-color: $dark-cyan;
      border-color: $dark-cyan;
    }
  }
}

.btn-secondary {
  color: $white;
  background-color: $orange;
  border-color: $orange;
  @include font-smoothing;

  &:hover,
  &:focus {
    color: $orange;
    background-color: $white;
    border-color: $orange;
  }
  &.btn-inverted {
    color: $orange;
    background-color: $white;
    border-color: $orange;

    &:hover,
    &:focus {
      color: $white;
      background-color: $orange;
      border-color: $orange;
    }
  }
}

.sq-form-submit {
  font-family: Helvetica, Arial, sans-serif;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  line-height: 1.25;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 2px solid transparent;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  @include sq-transition(0.2s all ease-in-out);
  color: $btn-primary__color--hover;
  background-color: $btn-primary__bg--hover;
  border-color: $btn-primary__border--hover;
  &:hover,
  &:focus {
    color: $btn-primary__color;
    background-color: $btn-primary__bg;
    border-color: $btn-primary__border;
  }
}

.btn-success {
  color: $btn-success__color;
  background-color: $btn-success__bg;
  border-color: $btn-success__border;

  &:hover,
  &:focus {
    color: $btn-success__color--hover;
    background-color: $btn-success__bg--hover;
    border-color: $btn-success__border--hover;
  }
}

.btn-info {
  color: $btn-info__color;
  background-color: $btn-info__bg;
  border-color: $btn-info__border;

  &:hover,
  &:focus {
    color: $btn-info__color--hover;
    background-color: $btn-info__bg--hover;
    border-color: $btn-info__border--hover;
  }
}

.btn-warning {
  color: $btn-warning__color;
  background-color: $btn-warning__bg;
  border-color: $btn-warning__border;

  &:hover,
  &:focus {
    color: $btn-warning__color--hover;
    background-color: $btn-warning__bg--hover;
    border-color: $btn-warning__border--hover;
  }
}

.btn-danger {
  color: $btn-danger__color;
  background-color: $btn-danger__bg;
  border-color: $btn-danger__border;

  &:hover,
  &:focus {
    color: $btn-danger__color--hover;
    background-color: $btn-danger__bg--hover;
    border-color: $btn-danger__border--hover;
  }
}

.btn-social,
.atcb-link {
  color: $white;
  background-color: $dark-cyan;
  border: none;
  font-size: pxToRem(18);
  font-weight: bold;
  @include font-smoothing;
  padding-top: pxToRem(14);
  padding-bottom: pxToRem(14);

  + .btn-social,
  + .addtocalendar,
  .addthis-toolbox + &,
  .addtocalendar + &,
  + .addthis-toolbox {
    margin-left: 0.75rem;
  }

  &:hover,
  &:focus {
    color: $white;
    background-color: $black;
  }
}

.btn-block {
  width: 100%;
  display: block;

  + .btn-block {
    margin-top: 0.5rem;
  }
}

.btn-small {
  font-size: 0.8rem;
  padding: 0.4rem 0.75rem;
}

.btn-large {
  font-size: 1.25rem;
  padding: 0.75rem 1.5rem;
}

.btn-filter {
  cursor: pointer;
  padding: pxToRem(7);
  margin-bottom: 0;
  position: absolute;
  right: 2.5rem;
  left: 0;
}

.social-share__box {
  text-align: right;
  margin: pxToRem(20) 0 pxToRem(40) 0;
}

/* stylelint-disable */
.addthis_button {
  border-radius: pxToRem(50);
  padding: 0.875rem pxToRem(20);
  position: relative;
  background: $dark-cyan;

  &:hover {
    background: $dark-navy;
  }

  .fas {
    margin-left: pxToRem(40);
    font-size: pxToRem(16);
  }

  // .icon-chevron {
  //     background: $white;

  //     &:after {
  //         border-bottom: pxToRem(1) solid $dark-cyan;
  //         border-right: pxToRem(1) solid $dark-cyan;
  //         border-top: 0 none;
  //         @include sq-transform(rotate(45deg) translateX(-6px) translateY(-6px));
  //     }
  // }
}
/* stylelint-enable */

// You can wrap the content styles in a class by un-commenting the following lines
// to nest the content.scss styles inside .content
.main__content {
  @import "content.scss";
  font-size: pxToRem(14);
  line-height: 1.64;
}

.main-content__wrapper,
.main__form form {
  @include sq-flex-box;
  @include sq-flex-direction(column);
  width: auto;
  min-width: 1px;

  .home & {
    padding: 0;
    max-width: 100%;
    display: block;
  }
}

//Icons
.icon-image {
  height: 100%;
  width: 100%;
}

.main {
  background-color: $white;
  position: relative;
  transition: 0.3s ease transform;
  will-change: transform;
}

.main-section {
  padding: pxToRem(60) pxToRem(20);
  max-width: $page__max-width;
  margin: 0 auto;
}

.main__footer {
  position: relative;
  transition: 0.3s ease transform;
  will-change: transform;
}

.wrapper {
  @include sq-flex-box;
  @include sq-flex-direction(column);
  max-width: $page__max-width;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1.5rem;
  padding-left: pxToRem(20);
  padding-right: pxToRem(20);

  + .wrapper {
    padding-top: 0;
  }

  .home & {
    padding: 0;
    max-width: 100%;
    display: block;
  }
}

.tool-box {
  @include sq-flex-box;
  @include sq-flex-basis(100%);
  @include sq-align-items(center);
  @include sq-justify-content(space-between);
  margin-bottom: 2rem;
}

.tools-social {
  display: none;

  > * {
    margin-right: 1rem;
    &:last-child() {
      margin-right: 0;
    }
  }
}

// _::-webkit-full-page-media, _:future, :root .tool-box {
//     @include sq-flex-basis(auto);
// }

.home {
  .page-heading {
    display: none;
  }
}

.space-between {
  @include sq-justify-content(space-between);
}

.compat-object-fit {
  background-position: center center;
  background-size: cover;

  img {
    opacity: 0;
  }
}

.fas {
  margin-left: pxToRem(7);
  font-size: pxToRem(16);
}

.no-content-styles .search-result__item {
  padding-left: 0rem;
}

.no-content-styles .search-result__item::before {
    display: none;

}
.pagination .pagination__item::before {
    display: none;
 
}

.pagination li::before {
    display: none !important;
}

/*
--------------------
Modules
--------------------
*/
