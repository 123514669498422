/* Module: S */
.related-content {
    padding: pxToRem(48) 0;

    &__wrapper {
    	margin-top: 0;
    }

    &__item {
        width: 50%;
    }
}