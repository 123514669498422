/* Module: S */
.featured-search {
    button {
        padding: pxToRem(15);
        width:  pxToRem(70);
        height: pxToRem(70);

        svg {
            padding: 0;
            width:  pxToRem(25);
            height: pxToRem(50);
            margin-top:  pxToRem(5);
            margin-left: pxToRem(5);

            &.search-icon {
                background: none;
            }
        }

        &:hover,
        &:focus {
            .search-icon {
                fill: $dark-cyan;
            }
        }
    }
}
