/* Module: S */
.featured-search {
    max-width: pxToRem(765);
    margin: 0 auto;
    padding-top: pxToRem(40);
    padding-bottom: pxToRem(30);

    form {
        padding: 0 pxToRem(20);
        position: relative;
        @include placeholder-color($dark-cyan);
    }

    input[type="text"] {
        background-color: $white-two;
        font-size: pxToRem(18);
        border: none;
        padding: pxToRem(20) pxToRem(55) pxToRem(20) pxToRem(18);
        width: 100%;
        color: $dark-cyan;
        border-radius: pxToRem(40);
        @include placeholder {
            font-weight: bold;
        }
    }

    button {
        background-color: transparent;
        position: absolute;
        top: 0;
        bottom: 0;
        right: pxToRem(20);
        border: none;
        padding: 0 pxToRem(13);

        svg {
            fill: $white-three;
            width: pxToRem(35);
            height: pxToRem(35);
            margin-top: pxToRem(15);
            padding: pxToRem(7);
            padding-top: pxToRem(10);
        }
    }

    + h2 {
        color: $black;
    }
}
