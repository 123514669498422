/* Module: S */
.featured-search {
    padding-top: pxToRem(60);
    padding-bottom: pxToRem(20);

    input[type="text"] {
        font-size: pxToRem(18);
        padding: pxToRem(22) pxToRem(85) pxToRem(23) pxToRem(28);
    }
}
