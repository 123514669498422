/* Module: S */
.main__content {
    &-full {
        @include sq-order(inherit);     
        @include sq-flex-basis(100%);
        padding-right: pxToRem(326);   
    }
}

.rhs-filters {
    &__toggle {
        display: none;
    }

    &__main {
        display: block;
        padding: pxToRem(20) 0;
    }
}