/* Module: S */
.lhs__menu {
    margin-right: pxToRem(70);
    @include sq-flex-shrink(0);
    @include sq-flex-basis(pxToRem(280));

    .landing-image__container + .wrapper & {
        margin-top: pxToRem(-70);
    }
}

.lhs__menu-list {

    &.menu__level-2 {
        padding: 0 pxToRem(28);
    }
}

.lhs__menu-link {
    padding: pxToRem(18) pxToRem(28);

    &.current.has-children {
        &::after {
            top: pxToRem(22);
            right: pxToRem(28);
        }
    }
}

.featured-search + .wrapper {
    .lhs__menu {
        margin-top: 0;
    }
}

.rhs__column {
    display: block;
    @include sq-flex-basis(pxToRem(278));
    margin-top: pxToRem(-20);
    margin-left: $rhs-column__margin-left;

    .is-listing + & {
        @include sq-flex-shrink(0);
        @include sq-order(inherit);
    }
}

.rhs__column {
    margin-top: pxToRem(90);
    &.has_search {
        margin-top: pxToRem(-20);
    }
}

.rhs__panel {
    width: 100%;
}

.rhs-panel__link {
    max-width: $rhs-column__width + 2rem;
}

.form-fieldset__content {
    display: inherit;
    margin-top: 0;
}

.addtocalendar {
    .atcb-list {
        left: auto;
        right: 0;
    }
}
.btt__wrapper {
    padding: 0 2rem;
}


.toc__box {
    display: block;
}


.breadcrumbs__link,
.breadcrumbs__current {
    color: $white;
    position: relative;
    @include font-smoothing();
}

.breadcrumbs__link {
    padding-right: pxToRem(20);

    &:hover {
        color: $white;
    }

    &:after {
        border-color: $white;
        margin-left: pxToRem(20);
    }
}

$bigger-rhs__width: $rhs-column__width + 2rem;
$bigger-lhs__width: $lhs-menu__width + 2rem;

.map__description {
    width: 40%;
    position: absolute;
    bottom: pxToRem(-50);
    left: pxToRem(16);
}
.map__wrapper{
    position: relative;
    margin-bottom: calc(3rem + 50px);

}
