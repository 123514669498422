/* Module: S */
.featured-images {
    padding: pxToRem(40) 0 pxToRem(50) 0;
    background-color: $white-four;
    width: 100%;
    overflow: hidden;

    h2 {
        color: $dark-cyan;
    }

    &__wrapper {
        max-width: $page__max-width;
        margin: 0 auto;
        @include sq-flex-box();
        @include sq-flex-wrap(wrap);
    }

    &__item {
        float: left;
        padding: pxToRem(0) pxToRem(20);
        margin-top: pxToRem(35);
        width: 100%;

        &:first-of-type {
            margin-top: pxToRem(20);
        }

        > .featured-images__image {
            width: 100%;
            display: block;
            background-size: cover;
            background-position: center;
            height: pxToRem(232);
            position: relative;
            cursor: pointer;
            border-radius: pxToRem(10);
        }
    }


    &__info {
        //background-color: $white;
        padding: pxToRem(20) 0 0 0;

        p {
            font-size: pxToRem(14);
            font-weight: regular;
            line-height: 1.43;
            margin: 0;
            padding: pxToRem(3) 0 0 0;
            color: $black-five;
        }
    }

    &__date {
        font-size: pxToRem(14);
        font-weight: bold;
        text-transform: uppercase;
        color: #006fba;
    }

    &__title {
        font-size: pxToRem(33);
        font-weight: bold;
        line-height: 1.27;
        text-transform: uppercase;
        padding: pxToRem(8) 0 pxToRem(50) 0;
        color: $orange;

        span {
            display: inline-block;
            font-size: pxToRem(23);
            font-weight: bold;
            background: $white;
            padding: pxToRem(5) pxToRem(12);
            border-radius: pxToRem(30);
            letter-spacing: pxToRem(2);
        }
    }

    &__social {
        background-color: $orange;
        padding: pxToRem(26);
        //height: pxToRem(309);
        border-radius: pxToRem(10);

        .list-social__wrapper {
            li {
                margin-bottom: pxToRem(10);
            }
        }

        .footer-social {
            &__wrapper {
                margin: 0;
                padding: 0;
                background-color: transparent;
                //@include sq-justify-content(space-between);
            }

            &__link {
                // background-color: transparent;
                // display: inline-block;
                // width: 20%;
                @include sq-flex(0 0 pxToRem(29));
                width: pxToRem(29);
                height: pxToRem(29);
                margin-right: pxToRem(14);

                &:first-of-type {
                    margin-left: pxToRem(0);
                }

                &.vimeo {
                    padding-top: pxToRem(3);
                }

                & ~ a {
                    font-size: pxToRem(24);
                    font-weight: 500;
                    letter-spacing: 2px;
                }
            }
        }
    }

    .featured-images__social {
        .featured-images__title {
            span {
                position: relative;

                &:nth-of-type(2) {
                    top: pxToRem(-5);
                }

                &:nth-of-type(3) {
                    top: pxToRem(-10);
                }
            }
        }
    }
}

.list-social {
    &__wrapper {
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            li {
                width: 100%;
                margin: 0;
                padding: 0;
                @include sq-flex-box();
                @include sq-justify-content(center);
                align-items: center;
            }
        }
        a {
            @include sq-flex(1);
            flex-direction: column;
            color: $white;
            text-decoration: none;
            border-radius: pxToRem(5);
            font-family: $secondary__font-family;
            font-weight: 500;
        }
    }
}
